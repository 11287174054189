import { OnInit, Component, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { UserRole } from '../../../models/user.model';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../../services/device.service';
import { UiService } from '../../../services/ui.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { ProductService } from '../../../services/product.service';
import { AuthService } from '../../../services/auth.service';
import { CompanyInstance } from '../../../models/company.model';
import { DeviceInstance } from '../../../models/device.model';
import { CompanyService } from '../../../services/company.service';
import { ControlIstance } from '../../../models/control.model';
import { ControlsService } from '../../../services/controls.service';
import { ProductInstance } from '../../../models/product.model';
import { Router } from '@angular/router';
import { QualityControlDialogComponent } from '../../ui/quality-control-dialog/quality-control-dialog.component';
import { TaskIstance } from '../../../models/task.model';
import { ControlSchedulingIstance } from '../../../models/control-scheduling.model';
import { PlantInstance } from '../../../models/plant.model';
import { Utility } from '../../../utils/utility';
import { PageWithLoader } from "../page-with-loader";
import {ThemeService} from "../../../services/theme.service";
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-quality-controls',
    templateUrl: './quality-controls.component.html',
    styleUrls: ['./quality-controls.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class QualityControlsComponent extends PageWithLoader implements OnInit {

    lockRequest = false;

    isAdmin: boolean;

    columns = [];
    /** only if is normal user */
    company: CompanyInstance;

    plants: PlantInstance[] = [];

    selectedCompany: CompanyInstance;

    selectedPlant: PlantInstance;

    selectedCompanyId: number;

    selectedPlantId: number;

    controls: ControlIstance[];

    devices: DeviceInstance[];

    products: ProductInstance[];

    selectedProduct: any;

    editRow: number;

    isChild: boolean;

    tasks: TaskIstance[];

    private _subscriptions: Subscription[] = [];

    @ViewChild('description', { static: true }) descriptionTemplate: TemplateRef<any>;
    @ViewChild('scheduling', { static: true }) schedulingTemplate: TemplateRef<any>;
    @ViewChild('device', { static: true }) deviceTemplate: TemplateRef<any>;
    @ViewChild('product', { static: true }) productTemplate: TemplateRef<any>;
    @ViewChild('task', { static: true }) taskTemplate: TemplateRef<any>;
    @ViewChild('enabled', { static: true }) enabledTemplate: TemplateRef<any>;
    @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
    @ViewChild('addItem', { static: true }) addItemTemplate: TemplateRef<any>;

    constructor(
        private _ui: UiService,
        private _products: ProductService,
        private _controls: ControlsService,
        private _devices: DeviceService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _company: CompanyService,
        private _dialog: MatDialog,
        private _translate: TranslateService,
        private _router: Router,
        private _utility: Utility,
        public auth: AuthService,
        _themeService: ThemeService
    ) {
      super(_themeService);
    }

    async ngOnInit(): Promise<void> {

        const translationSubscription =
            this._translate.stream([
                'quality-controls.title',
                'quality-controls.columns.description',
                'quality-controls.columns.schedulings',
                'quality-controls.columns.devices',
                'quality-controls.columns.products',
                'quality-controls.columns.tasks',
                'quality-controls.columns.enabled'
            ]).subscribe((translations) => {
                this._navbar.setTitle(translations['quality-controls.title']);
                setTimeout(() => this._sidebar.setSelected('quality-controls'));
                this.columns = [{
                    resizable: false,
                    canAutoResize: true,
                    width: 350,
                    maxWidth: 500,
                    name: translations['quality-controls.columns.description'],
                    prop: 'description',
                    cellTemplate: this.descriptionTemplate
                }, {
                    resizable: false,
                    canAutoResize: true,
                    width: 250,
                    maxWidth: 500,
                    name: translations['quality-controls.columns.schedulings'],
                    prop: 'ControlSchedulings.lentgth',
                    cellTemplate: this.schedulingTemplate
                }, {
                    resizable: false,
                    canAutoResize: true,
                    width: 150,
                    maxWidth: 250,
                    name: translations['quality-controls.columns.devices'],
                    prop: 'Devices.lentgth',
                    cellTemplate: this.deviceTemplate
                }, {
                    resizable: false,
                    canAutoResize: true,
                    width: 150,
                    maxWidth: 250,
                    name: translations['quality-controls.columns.products'],
                    prop: 'Products.length',
                    cellTemplate: this.productTemplate
                }, {
                    resizable: false,
                    canAutoResize: true,
                    width: 150,
                    maxWidth: 250,
                    name: translations['quality-controls.columns.tasks'],
                    prop: 'Tasks.length',
                    cellTemplate: this.taskTemplate
                }, {
                    resizable: false,
                    canAutoResize: true,
                    width: 150,
                    maxWidth: 250,
                    name: translations['quality-controls.columns.enabled'],
                    prop: 'enabled',
                    cellTemplate: this.enabledTemplate
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    headerTemplate: this.addItemTemplate,
                    cellTemplate: this.actionsTemplate
                }];
            });
        this._subscriptions.push(translationSubscription);

        this.lockRequest = true;
        this.selectedCompanyId = Number(localStorage.getItem('companyId'));
        this.selectedPlantId = Number(localStorage.getItem('plantId'));

        // check if user is Admin
        this.isAdmin = this.auth.user.role === UserRole.admin;
        if (this.isAdmin) {
            await this.companyChanged(await this._company.getCompany(this.selectedCompanyId));
        } else {
            // if is user get company detailt to get plants
            this.company = await this._company.getCompany(this.selectedCompanyId);
            // set plants
            this.plants = this.company.Plants;
            await this._handleBuildPlantArrayObject(true);
            await this.plantChanged();
        }

        const companyChangeSubscription =
            this._company.changeCompanyEmitted$.subscribe(company => {
                if(this.isAdmin){
                    this.companyChanged(company);
                }
            });
        this._subscriptions.push(companyChangeSubscription);

        this.lockRequest = false;
    }

    ngOnDestroy(): void {
        // Unsubscribe all subscriptions to avoid memory leaks
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
            array.splice(index, 1);
        });
    }

    // only if user is admin can change company
    async companyChanged(company: CompanyInstance) {
        this.selectedCompany = company;
        this.selectedCompanyId = company.id;
        this.plants = company.Plants;
        const isSelectedPlantInCurrentCompany = this.selectedPlantId ?
        company.Plants.find((p) => p.id === this.selectedPlantId) : false;
        await this._handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany ? true : false);
        await this.plantChanged();
    }

    async _handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany: boolean) {
        // set plant
        if (!this.selectedPlantId || !isSelectedPlantInCurrentCompany) {
            this.selectedPlant = this.plants[0];
        } else if (this.plants.length > 0) {
            this.selectedPlant = this.plants.find((p) => p.id === this.selectedPlantId);
        }
    }

    async plantChanged() {
        if (this.selectedPlant) {
            this.selectedPlantId = this.selectedPlant.id;
            localStorage.setItem('plantId', this.selectedPlantId.toString());

            // get check for selected company
            const controls = await this._controls.getControls(this.selectedCompanyId, this.selectedPlantId);
            this.controls = this._cleanControlFromDisableTask(controls);

            // get devices for selected plant
            this.devices = await this._devices.getDevicesByPlant(this.selectedCompanyId, this.selectedPlantId);

            // get product for selected plant
            this.products = await this._products.getProducts(null, this.selectedPlantId);
            this.selectedProduct = -1;
            this.editRow = null;
        }
    }

    // if product change fetch new controls
    async productChanged() {
        // if value is -1 all products are selected so fetch all controls for this company
        if (this.selectedProduct === -1) {
            // get all controls for this company
            this.controls = await this._controls.getControls(this.selectedCompanyId, this.selectedPlantId);
        } else {
            // get all controls for given product
            this.controls = await this._controls.getControls(this.selectedCompanyId, this.selectedPlantId, this.selectedProduct.id);
        }
    }

    // add control, create new control then open controls detail page
    async addControl() {
        // HTTP reques to create new controls
        const check = await this._controls.create(this.selectedCompanyId, this.selectedPlantId);
        if (check) {
            this._router.navigate(['/quality-controls', check.id, 'details']);
        } else {
            this._ui.openSnackBar(this._translate.instant('quality-controls.created-error'));
        }
    }

    // deleting control
    async delete(controlId: number) {

        if (controlId) {
            const res = await this._controls.delete(controlId);
            if (res.status === 200) {
                this.controls = this.controls.filter(c => c.id !== controlId);
                this._ui.openSnackBar(this._translate.instant('quality-controls.deleted-successfully'));
            } else {
                this._ui.openSnackBar(this._translate.instant('quality-controls.deleted-error'));
            }
        }

        this.controls = this._utility.cloneArray(this.controls);
    }

    async duplicate(controlId: number) {

        if (controlId) {
            const res = await this._controls.duplicate(controlId);
            if (res) {
                this.controls.push(res);
                this._ui.openSnackBar(this._translate.instant('quality-controls.duplicated-successfully'));
            } else {
                this._ui.openSnackBar(this._translate.instant('quality-controls.duplicated-error'));
            }
        }

        this.controls = this._utility.cloneArray(this.controls);
    }


    // change enabled proprerty of control, passing controlId
    async enablingControl(controlId: number) {

        if (controlId) {
            const data = { enabled: !this.controls.find(c => c.id === controlId).enabled };
            const res = await this._controls.update(controlId, data);
            if (res) {
                this.controls[this.controls.findIndex(c => c.id === controlId)].enabled = res.enabled;
            } else {
                // TO-DO Messaggio di errore
            }
        }

        this.controls = this._utility.cloneArray(this.controls);
    }

    async edit(row: ControlIstance) {
        this._router.navigate(['/quality-controls', row.id, 'details']);
    }

    openDialog(id: number, value: string) {
        const control = this.controls.find(c => c.id === id);
        let labels: string[] = [];

        if (value === 'products') {

            const products = this._utility.cloneArray(control.Products);
            labels = products.map((prod) => prod.code);

            this._dialog.open(QualityControlDialogComponent, {
                width: '600px',
                data: {
                    key: 'quality-controls.columns.products',
                    values: labels,
                    needFilterBar: true
                }
            });

        } else if (value === 'all_products') {

            labels = this.products.map((prod) => prod.code);

            this._dialog.open(QualityControlDialogComponent, {
                width: '600px',
                data: {
                    key: 'quality-controls.columns.products',
                    values: labels,
                    needFilterBar: true
                }
            });

        } else if (value === 'product') {

            labels.push(control.Products[0].code);

            this._dialog.open(QualityControlDialogComponent, {
                width: '600px',
                data: {
                    key: 'quality-controls.columns.product',
                    values: labels
                }
            });

        } if (value === 'devices') {

            const devices = this._utility.cloneArray(control.Devices);
            labels = devices.map((d) => d.label);

            this._dialog.open(QualityControlDialogComponent, {
                width: '600px',
                data: {
                    key: 'quality-controls.columns.devices',
                    values: labels,
                    needFilterBar: true
                }
            });

        } else if (value === 'all_devices') {

            labels = this.devices.map((d) => d.label);

            this._dialog.open(QualityControlDialogComponent, {
                width: '600px',
                data: {
                    key: 'quality-controls.columns.devices',
                    values: labels,
                    needFilterBar: true
                }
            });

        } else if (value === 'device') {

            labels.push(control.Devices[0].label);

            this._dialog.open(QualityControlDialogComponent, {
                width: '600px',
                data: {
                    key: 'quality-controls.columns.device',
                    values: labels
                }
            });

        } else if (value === 'tasks') {

            const tasks = this._utility.cloneArray(control.Tasks);
            labels = tasks.map((task) => task.description);

            this._dialog.open(QualityControlDialogComponent, {
                width: '600px',
                data: {
                    key: 'quality-controls.columns.tasks',
                    values: labels
                }
            });

        } else if (value === 'task') {

            labels.push(control.Tasks[0].description);

            this._dialog.open(QualityControlDialogComponent, {
                width: '600px',
                data: {
                    key: 'quality-controls.columns.task',
                    values: labels
                }
            });
        } else if (value === 'schedulings') {

            labels = this._formattingSchedulingLabel(control.ControlSchedulings);

            this._dialog.open(QualityControlDialogComponent, {
                width: '600px',
                data: {
                    key: 'quality-controls.columns.schedulings',
                    values: labels
                }
            });
        } else if (value === 'scheduling') {

            labels = this._formattingSchedulingLabel(control.ControlSchedulings);

            this._dialog.open(QualityControlDialogComponent, {
                width: '600px',
                data: {
                    key: 'quality-controls.columns.scheduling',
                    values: labels
                }
            });
        }

    }

    _formattingSchedulingLabel(schedulings: ControlSchedulingIstance[]): string[] {
        const labels: string[] = [];
        const piece = this._translate.instant('units.piece');
        const pieces = this._translate.instant('units.pieces');
        const minute = this._translate.instant('units.minute');
        const minutes = this._translate.instant('units.minutes');

        for (const sche of schedulings) {

            if (sche.type === 2 && sche.quantity === 0) {
                labels.push(this._translate.instant('quality-controls.scheduling.startup',
                    {
                        prod: sche.repeat,
                        pieces1: sche.repeat > 1 ? pieces : piece
                    }));
            } else if (sche.type === 1 && !sche.cycle) {
                labels.push(this._translate.instant('quality-controls.scheduling.after-pieces',
                    {
                        prod: sche.repeat,
                        pieces1: sche.repeat > 1 ? pieces : piece,
                        item: sche.quantity,
                        pieces2: sche.quantity > 1 ? pieces : piece
                    }));
            } else if (sche.type === 1 && sche.cycle) {
                labels.push(this._translate.instant('quality-controls.scheduling.each-pieces',
                    {
                        prod: sche.repeat,
                        pieces1: sche.repeat > 1 ? pieces : piece,
                        item: sche.quantity,
                        pieces2: sche.quantity > 1 ? pieces : piece
                    }));
            } else if (sche.type === 2 && !sche.cycle) {
                labels.push(this._translate.instant('quality-controls.scheduling.after-minutes',
                    {
                        prod: sche.repeat,
                        pieces1: sche.repeat > 1 ? pieces : piece,
                        item: sche.quantity,
                        pieces2: sche.quantity > 1 ? minutes : minute
                    }));
            } else if (sche.type === 2 && sche.cycle) {
                labels.push(this._translate.instant('quality-controls.scheduling.each-minutes',
                    {
                        prod: sche.repeat,
                        pieces1: sche.repeat > 1 ? pieces : piece,
                        item: sche.quantity,
                        pieces2: sche.quantity > 1 ? minutes : minute
                    }));
            } else if (sche.type === 3) {
                labels.push(this._translate.instant('quality-controls.scheduling.after-stop',
                    {
                        prod: sche.repeat,
                        pieces1: sche.repeat > 1 ? pieces : piece
                    }));
            }

        }

        return labels;
    }

    private _cleanControlFromDisableTask(controls: ControlIstance[]) {
        const cleanControls: ControlIstance[] = [];
        for (const control of controls) {
            control.Tasks = control.Tasks.filter((task) => {
                return task.ControlsTasks.enabled;
            });

            cleanControls.push(control);
        }

        return cleanControls;
    }
}
