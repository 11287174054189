import { ViewEncapsulation, Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceInstance } from '../../../models/device.model';
import { DeviceService } from '../../../services/device.service';
import { PluginService } from '../../../services/plugin.service';
import { PluginInstance } from '../../../models/plugin.model';
import { UiService } from '../../../services/ui.service';
import {PageWithLoader} from "../page-with-loader";
import {ThemeService} from "../../../services/theme.service";

@Component({
    selector: 'app-plugin',
    templateUrl: './plugin.component.html',
    styleUrls: ['./plugin.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class PluginComponent extends PageWithLoader implements OnInit {
    lockRequest = false;
    devices: DeviceInstance[];
    selectedDevice: DeviceInstance;
    columns = [];
    plugins: PluginInstance[] = [];
    tmpPlugins: PluginInstance[] = [];
    editRow: number;
    modified = false;

    @ViewChild('code', { static: true }) codeTemplate: TemplateRef<any>;
    @ViewChild('description', { static: true }) descriptionTemplate: TemplateRef<any>;
    @ViewChild('enabled', { static: true }) enabledTemplate: TemplateRef<any>;
    @ViewChild('isOnSatellite', { static: true }) satelliteTemplate: TemplateRef<any>;
    @ViewChild('addItem', { static: true }) addItemTemplate: TemplateRef<any>;
    @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;

    constructor(
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _translate: TranslateService,
        private _device: DeviceService,
        private _plugin: PluginService,
        private _ui: UiService,
        _themeService: ThemeService
    ) {
      super(_themeService);
    }

    async ngOnInit(): Promise<void> {
        this.lockRequest = true;

        this._translate.stream([
            'plugin.title',
            'plugin.columns.code',
            'plugin.columns.description',
            'plugin.columns.enabled',
            'plugin.columns.isOnSatellite'
        ]).subscribe((translations) => {
            this._navbar.setTitle(translations['plugin.title']);

            setTimeout(() => this._sidebar.setSelected('plugin'));
            this.columns = [{
                resizable: true,
                canAutoResize: true,
                name: translations['plugin.columns.code'],
                prop: 'code',
                cellTemplate: this.codeTemplate
            }, {
                resizable: true,
                canAutoResize: true,
                name: translations['plugin.columns.description'],
                prop: 'description',
                cellTemplate: this.descriptionTemplate
            }, {
                resizable: true,
                canAutoResize: true,
                name: translations['plugin.columns.enabled'],
                prop: 'PluginsDevices.enabled',
                cellTemplate: this.enabledTemplate
            }, {
                resizable: true,
                canAutoResize: true,
                name: translations['plugin.columns.isOnSatellite'],
                prop: 'PluginsDevices.isOnSatellite',
                cellTemplate: this.satelliteTemplate
            },
            {
                resizeable: false,
                canAutoResize: true,
                width: 160,
                name: '',
                headerTemplate: this.addItemTemplate,
                cellTemplate: this.actionsTemplate
            }];
        });

        this.devices = await this._device.getDevices();
        if (this.devices.length > 0) {
            if (localStorage.getItem('deviceId')) {
                const device = this.devices.find((item) => item.id === Number(localStorage.getItem('deviceId')));
                if (device) {
                    this.selectedDevice = device;
                }
            } else {
                this.selectedDevice = this.devices[0];
            }
            this.deviceChanged();
        }
    }

    async deviceChanged() {
        this.plugins = [];
        this.tmpPlugins = [];
        localStorage.setItem('deviceId', this.selectedDevice.id.toString());
        const plugins = await this._plugin.getPlugins(this.selectedDevice.id);
        for (const p of plugins) {
            if (!p.PluginsDevices) {
                p.PluginsDevices = {
                    deviceId: this.selectedDevice.id,
                    pluginId: p.id,
                    enabled: false,
                    isOnSatellite: false
                };
            }
        }

        this.plugins = plugins;
        this.tmpPlugins = JSON.parse(JSON.stringify(this.plugins));
        this.editRow = null;
        this.modified = false;
        this.lockRequest = false;
    }

    async addPlugin() {
        if (this.editRow || this.editRow === 0) {
            return;
        }
        const newPlugin: PluginInstance = {
            code: '',
            description: '',
            PluginsDevices: {
                deviceId: this.selectedDevice.id,
                enabled: true,
                isOnSatellite: true
            }
        };

        this.plugins.unshift(newPlugin);
        this.plugins = [...this.plugins];
        this.tmpPlugins = JSON.parse(JSON.stringify(this.plugins));
        this.editRow = 0;
    }

    isEditMode(rowIndex: number) {
        return (this.editRow || this.editRow === 0) && !this.isEditRow(rowIndex);
    }

    isEditRow(rowIndex: number) {
        return this.editRow === rowIndex;
    }

    isModified() {
        return this.modified;
    }

    setModified(index) {
        this.modified = true;
    }

    back(rowIndex: number) {
        if (this.plugins[rowIndex].id) {
            this.tmpPlugins[rowIndex] = JSON.parse(JSON.stringify(this.plugins[rowIndex]));
        } else {
            this.plugins.shift();
            this.tmpPlugins.shift();
        }
        this.plugins = [...this.plugins];
        this.tmpPlugins = [...this.tmpPlugins];

        this.editRow = null;
        this.modified = false;
    }

    async save(row: PluginInstance, index: number) {
        let isOk = false;
        if (row.id) {
            const plugin: PluginInstance = await this._plugin.updatePlugin(this.selectedDevice.id, row);
            isOk = plugin ? true : false;
            if (isOk) {
                const idx = this.plugins.findIndex(p => p.id === row.id);
                this.plugins[idx] = plugin;
                this.tmpPlugins = JSON.parse(JSON.stringify(this.plugins));
            }
        } else {
            this.plugins.shift();
            const plugin: PluginInstance = await this._plugin.createPlugin(this.selectedDevice.id, row);
            isOk = plugin ? true : false;
            if (isOk) {
                this.plugins.push(plugin);
                this.tmpPlugins = JSON.parse(JSON.stringify(this.plugins));
            }
        }
        this.editRow = null;
        this.modified = false;
        if (isOk) {
            this._ui.openSnackBar(this._translate.instant('plugin.saved-success'));
        } else {
            this._ui.openSnackBar(this._translate.instant('plugin.saved-error'));
        }
    }

    edit(rowIndex: number) {
        this.editRow = rowIndex;
    }
}
