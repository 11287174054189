import { UiService } from '../../../services/ui.service';
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation, Inject, LOCALE_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DeviceService } from '../../../services/device.service';

import { DeviceInstance } from '../../../models/device.model';
import { ProductInstance } from '../../../models/product.model';

import { TranslateService } from '@ngx-translate/core';
import { SidebarService } from '../../../services/sidebar.service';

@Component({
    selector: 'app-devices-dialog',
    templateUrl: './devices-dialog.component.html',
    styleUrls: ['./devices-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DevicesDialogComponent implements OnInit {
    devices: DeviceInstance[];
    selected: DeviceInstance[] = [];

    isSelectAll = false;

    columns = [];
    search: string;

    @ViewChild('checkbox', { static: true }) checkboxTemplate: TemplateRef<any>;
    @ViewChild('checkboxHeader', { static: true }) checkboxHeaderTemplate: TemplateRef<any>;

    constructor(
        private _ref: MatDialogRef<DevicesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { allDevices?: DeviceInstance[], selectedDevices?: DeviceInstance[], companyId: number },
        
        private _device: DeviceService,
        private _ui: UiService,
        private _translate: TranslateService,
        public _sidebar: SidebarService
    ) { }

    async ngOnInit() {
        this._translate.get('dialogs.devices.select').subscribe((res) => {
            this.columns = [{
                name: 'dialog.devices.select-all',
                resizeable: false,
                canAutoResize: false,
                width: 250,
                cellTemplate: this.checkboxTemplate,
                headerTemplate: this.checkboxHeaderTemplate
            }, {
                resizeable: false,
                name: res,
                prop: 'label',
                canAutoResize: false,
                width: 300
            }];
        });
        
        if (this.data.allDevices && this.data.allDevices.length > 0) {
            this.devices = this.data.allDevices;
        } else {
            this.devices = await this._device.getDevicesByCompany(this.data.companyId);
        }

        if (this.data.selectedDevices) {
            this.selected = this.data.selectedDevices;
        } else {
            this.selected = [...this.devices];
        }
        
        if (this.selected.length === this.devices.length) {
            this.isSelectAll = true;
        }
    }

    onNoClick() {
        this._ref.close();
    }

    confirm() {
        this._ref.close(this.selected);
    }

    isSelected(id) {
        return !!this.selected.find(e => e.id === id);
    }

    rowIdentityComparator(row: DeviceInstance) {
        return row.id;
    }

    isSelectedAll() {
        return this.isSelectAll;
    }

    selectAll() {
        if (!this.isSelectAll) {
            this.selected = [...this.devices];
            this.isSelectAll = true;
        } else {
            this.selected = [];
            this.isSelectAll = false;
        }

    }
}
