import { Component, ViewEncapsulation, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavbarService } from '../../../services/navbar.service';
import { CompanyInstance } from '../../../models/company.model';
import { DeviceInstance } from '../../../models/device.model';
import { AuthService } from '../../../services/auth.service';
import { UserRole } from '../../../models/user.model';
import { CompanyService } from '../../../services/company.service';
import { DeviceService } from '../../../services/device.service';
import { SidebarService } from '../../../services/sidebar.service';
import { ErrorCategoryInstance } from '../../../models/error-category.model';
import { ErrorService } from '../../../services/error.service';
import { ErrorType } from '../../../models/error.model';
import { UiService } from '../../../services/ui.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../ui/dialog/dialog.component';



@Component({
    selector: 'app-control',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CategoriesComponent implements OnInit {

    isAdmin: boolean;

    companies: CompanyInstance[];

    devices: DeviceInstance[];

    selectedCompany: CompanyInstance;

    selectedCompanyId: number;

    modifiedPlanned: boolean;

    modifiedUnplanned: boolean;

    editRowPlanned: number;

    editRowUnplanned: number;

    plannedColumns: any[] = [];

    unPlannedColumns: any[] = [];

    errorCategoryList: ErrorCategoryInstance[];

    plannedList: ErrorCategoryInstance[];

    tmpPlannedList: ErrorCategoryInstance[];

    unPlannedList: ErrorCategoryInstance[];

    tmpUnPlannedList: ErrorCategoryInstance[];

    @ViewChild('namePlanned', { static: true }) namePlannedTemplate: TemplateRef<any>;
    @ViewChild('actionsPlanned', { static: true }) actionsPlannedTemplate: TemplateRef<any>;
    @ViewChild('addItemPlanned', { static: true }) addItemPlannedTemplate: TemplateRef<any>;
    @ViewChild('nameUnplanned', { static: true }) nameUnplannedTemplate: TemplateRef<any>;
    @ViewChild('actionsUnplanned', { static: true }) actionsUnplannedTemplate: TemplateRef<any>;
    @ViewChild('addItemUnplanned', { static: true }) addItemUnplannedTemplate: TemplateRef<any>;

    constructor(
        private _device: DeviceService,
        private _company: CompanyService,
        private _auth: AuthService,
        private _navbar: NavbarService,
        private _translate: TranslateService,
        private _sidebar: SidebarService,
        private _error: ErrorService,
        private _ui: UiService,
        private _dialog: MatDialog,
    ) { }

    async ngOnInit() {

        this.isAdmin = this._auth.user.role === UserRole.admin;

        this._translate.stream([
            'categories.title',
            'categories.planned',
            'categories.unplanned',
        ]).subscribe(async (translations) => {
            this._navbar.setTitle(translations['categories.title']);
            setTimeout(() => this._sidebar.selected = 'categories');

            this.plannedColumns = [{
                resizable: false,
                canAutoResize: true,
                width: 400,
                maxWidth: 650,
                name: translations['categories.planned'],
                prop: 'name',
                cellTemplate: this.namePlannedTemplate
            }, {
                resizeable: true,
                canAutoResize: false,
                width: 200,
                maxWidth: 300,
                name: '',
                headerTemplate: this.addItemPlannedTemplate,
                cellTemplate: this.actionsPlannedTemplate
            }];

            this.unPlannedColumns = [{
                resizable: false,
                canAutoResize: true,
                width: 400,
                maxWidth: 650,
                name: translations['categories.unplanned'],
                prop: 'name',
                cellTemplate: this.nameUnplannedTemplate
            }, {
                resizeable: true,
                canAutoResize: false,
                width: 200,
                maxWidth: 300,
                name: '',
                headerTemplate: this.addItemUnplannedTemplate,
                cellTemplate: this.actionsUnplannedTemplate
            }];

        });

        this.selectedCompanyId = Number(localStorage.getItem('companyId'));

        if (this.isAdmin) {
            this.companies = await this._company.getCompanies();

            if (this.selectedCompanyId) {
                this.selectedCompany = this.companies.find((company) => company.id === this.selectedCompanyId);
                await this.companyChanged();
            } else if (this.companies.length > 0) {
                this.selectedCompany = this.companies[0];
                await this.companyChanged();
            }
        } else {
            this.devices = await this._device.getDevices();
            if (!this.selectedCompanyId && this.devices.length > 0) {
                this.selectedCompanyId = this.devices[0].Company.id;
            }
            await this._createErrorCategoriesColums(await this._error.getErrorCategories(this.selectedCompanyId));
            this._setUnmodified();
        }

    }

    async companyChanged(): Promise<void> {
        this.selectedCompanyId = this.selectedCompany.id;
        localStorage.setItem('companyId', this.selectedCompanyId.toString());
        await this._createErrorCategoriesColums(await this._error.getErrorCategories(this.selectedCompany.id));
        this._setUnmodified();
    }

    private async _createErrorCategoriesColums(categories: ErrorCategoryInstance[]): Promise<void> {

        this.plannedList = [];
        this.unPlannedList = [];

        for (const category of categories) {

            if (category.type === ErrorType.planned) {
                this.plannedList.push(category);
            } else {
                this.unPlannedList.push(category);
            }
        }

        this.tmpPlannedList = this._clone(this.plannedList);
        this.tmpUnPlannedList = this._clone(this.unPlannedList);

    }

    public add(errorType: ErrorType): void {

        if (errorType === ErrorType.planned) {
            if (this.editRowPlanned || this.editRowPlanned === 0) {
                return;
            }
            const temporaryNewCategory = {
                name: '',
                type: ErrorType.planned,
                companyId: this.selectedCompanyId
            };

            this.plannedList.unshift(temporaryNewCategory);
            this.tmpPlannedList = this._clone(this.plannedList);
            this.setModified(ErrorType.planned, 0);

        } else if (errorType === ErrorType.unplanned) {

            if (errorType === ErrorType.unplanned) {
                if (this.editRowUnplanned || this.editRowUnplanned === 0) {
                    return;
                }
                const temporaryNewCategory = {
                    name: '',
                    type: ErrorType.unplanned,
                    companyId: this.selectedCompanyId
                };

                this.unPlannedList.unshift(temporaryNewCategory);
                this.tmpUnPlannedList = this._clone(this.unPlannedList);
                this.setModified(ErrorType.unplanned, 0);
            }
        }
    }

    public async save(category: ErrorCategoryInstance, row: number): Promise<void> {

        if (category.id) {
            const result = await this._error.updateErrorCategories(category.id, category.name);
            if (result === 200) {
                this._ui.openSnackBar(this._translate.instant('categories.saved-successfully'));
            } else {
                this._ui.openSnackBar(this._translate.instant('categories.saved-error'));
                this.back(category.type, row);
                return;
            }
        } else {
            category = await this._error.createErrorCategories(category);
            if (category) {
                this._ui.openSnackBar(this._translate.instant('categories.saved-successfully'));
            } else {
                this._ui.openSnackBar(this._translate.instant('categories.saved-error'));
                this.back(category.type, row);
                return;
            }
        }

        if (category.type === ErrorType.planned) {
            this.plannedList[row] = category;
            this.tmpPlannedList = this._clone(this.plannedList);
            this._setUnmodified(ErrorType.planned);
        } else if (category.type === ErrorType.unplanned) {
            this.unPlannedList[row] = category;
            this.tmpUnPlannedList = this._clone(this.unPlannedList);
            this._setUnmodified(ErrorType.unplanned);
        }
    }

    public delete(category: ErrorCategoryInstance, row: number) {

        if (category.type === ErrorType.planned) {

            if (!category.id) {
                this.plannedList.shift();
                this.tmpPlannedList = this._clone(this.plannedList);
                this._setUnmodified(ErrorType.planned);
                return;
            } else {

                const ref = this._dialog.open(DialogComponent, {
                    width: '600px',
                    data: {
                        title: this._translate.instant('categories.dialog.title'),
                        message: this._translate.instant('categories.dialog.text', { prod: category.name }),
                        disabled: 'no'
                    }
                });

                ref.afterClosed().subscribe(async (result) => {
                    if (result) {
                        const resultStatus = await this._error.deleteErrorCategories(category.id);
                        if (resultStatus === 400) {
                            this._ui.openSnackBar(this._translate.instant('categories.category-used'));
                            return;
                        }
                        this.plannedList.splice(row, 1);
                        this.tmpPlannedList = this._clone(this.plannedList);
                        this._ui.openSnackBar(this._translate.instant('categories.deleted-successfully'));
                        this._setUnmodified(ErrorType.planned);
                    }
                });
            }
        } else if (category.type === ErrorType.unplanned) {

            if (!category.id) {
                this.unPlannedList.shift();
                this.tmpUnPlannedList = this._clone(this.unPlannedList);
                this._setUnmodified(ErrorType.unplanned);
                return;
            } else {

                const ref = this._dialog.open(DialogComponent, {
                    width: '600px',
                    data: {
                        title: this._translate.instant('categories.dialog.title'),
                        message: this._translate.instant('categories.dialog.text', { prod: category.name }),
                        disabled: 'no'
                    }
                });

                ref.afterClosed().subscribe(async (result) => {
                    if (result) {
                        const resultStatus = await this._error.deleteErrorCategories(category.id);
                        if (resultStatus === 400) {
                            this._ui.openSnackBar(this._translate.instant('categories.category-used'));
                            return;
                        }
                        this.unPlannedList.splice(row, 1);
                        this.tmpUnPlannedList = this._clone(this.unPlannedList);
                        this._ui.openSnackBar(this._translate.instant('categories.deleted-successfully'));
                        this._setUnmodified(ErrorType.unplanned);
                    }
                });
            }
        }
    }

    public back(errorType: ErrorType, row: number): void {

        if (errorType === ErrorType.planned) {

            if (this.plannedList[row].id) {
                this.tmpPlannedList[row].name = this.plannedList[row].name;
            } else {
                this.plannedList.shift();
            }

            this.tmpPlannedList = this._clone(this.plannedList);

        } else if (errorType === ErrorType.unplanned) {

            if (this.unPlannedList[row].id) {
                this.tmpUnPlannedList[row].name = this.unPlannedList[row].name;
            } else {
                this.unPlannedList.shift();
            }

            this.tmpUnPlannedList = this._clone(this.unPlannedList);
        }

        this._setUnmodified(errorType);

    }

    public setModified(errorType: ErrorType, row: number): void {

        if (errorType === ErrorType.planned) {
            this.modifiedPlanned = true;
            this.editRowPlanned = row;
        } else if (errorType === ErrorType.unplanned) {
            this.modifiedUnplanned = true;
            this.editRowUnplanned = row;
        }
    }

    private _setUnmodified(errorType?: ErrorType): void {

        if (errorType === ErrorType.planned) {
            this.modifiedPlanned = false;
            this.editRowPlanned = null;
        } else if (errorType === ErrorType.unplanned) {
            this.modifiedUnplanned = false;
            this.editRowUnplanned = null;
        } else {
            this.modifiedPlanned = false;
            this.editRowPlanned = null;
            this.modifiedUnplanned = false;
            this.editRowUnplanned = null;
        }
    }

    private _clone(array: any[]): any[] {
        return array.map(x => Object.assign({}, x));
    }
}
