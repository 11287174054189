import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

export enum GenericDialogComponentModes {
    save = 'save',
    exitSave = 'exit-save',
    cancel = 'cancel',
    delete = 'delete',
}

export interface GenericDialogComponentData {
    buttons?: { save?: button, exitSave?: button, cancel?: button, }
    mode?: GenericDialogComponentModes;
    title: string;
    message: string;
}

export interface button {
    class: string,
    text: string,
    type: string,
}

@Component({
    selector: 'app-generic-dialog',
    templateUrl: './generic-dialog.component.html',
    styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent implements OnInit {
    @Input() data: GenericDialogComponentData;
    @Output() primaryButtonClicked = new EventEmitter<void>();
    @Output() secondaryButtonClicked = new EventEmitter<void>();
    @Output() cancelButtonClicked = new EventEmitter<void>();
    

    constructor(        
    ) { }

    async ngOnInit() {

    }

    onButtonClicked (choice: string) {
        switch (choice) {
            case 'primary':
                this.primaryButtonClicked.emit();       
                break;
            case 'secondary':
                this.secondaryButtonClicked.emit();
                break;
            case 'cancel':
                this.cancelButtonClicked.emit();
                break;
            default:
                break;
        }
    }

    onClose() {
        
    }
}
