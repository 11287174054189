import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { Subscription } from 'rxjs';

import { ProductInterface, ProductType } from '../../../models/product.model';

@Component({
    selector: 'app-product-info-drawer',
    templateUrl: './product-info-drawer.component.html',
    styleUrls: ['./product-info-drawer.component.scss']
})
export class ProductInfoDrawerComponent implements OnInit {

    accordions = [];
    tabs = [];
    selectedTabId: number = 1;

    @Input() product?: ProductInterface;
    columns: TsTreeListColumn[] = [];

    additionalInfos?: { description: string, value: string }[];

    private _subscriptions: Subscription[] = [];

    constructor(
        private readonly _translate: TranslateService,
    ) { }

    ngOnInit(): void {
        const translateSubscription =
        this._translate.stream([
            'products.drawer.product-registry',
            'products.drawer.associated-resources',
        ]).subscribe((translations) => {
            this.tabs = [{
                id: 1,
                text: translations['products.drawer.product-registry'],
                disabled: false,
            }, {
                id: 2,
                text: translations['products.drawer.associated-resources'],
                disabled: false,
            }];          
        });
        this._subscriptions.push(translateSubscription);

        this.accordions = [];

        for (let i = 0; i < this.product.Devices.length; i++) {
            const element = this.product.Devices[i];
            this.accordions.push({
                index: i,
                opened: false,
                title: element.label,
                ...element,
            })
        }
        const tempObj = JSON.parse(this.product.jsonInfo);
        this.additionalInfos = [];

        for(let i = 0; tempObj && i < Object.keys(tempObj).length; i++){
            const key = `${Object.keys(tempObj)[i]}`;
            const value = `${tempObj[key]}`;
            this.additionalInfos.push({ description: key, value: value });
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe all subscriptions to avoid memory leaks
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
            array.splice(index, 1);
        });
    }

    translateProductType(type: ProductType): string {
        switch (type) {
            case ProductType.product:
                return 'products.type.product';
            case ProductType.semiFinished:
                return 'products.type.semiFinished';
            case ProductType.rawMaterial:
                return 'products.type.rawMaterial';
            default:
                return 'undefined';
        }
    }

    onAccordionClick(opened: boolean, accordion: any): void {
        this.accordions[accordion.index].opened = opened;
    }

}
