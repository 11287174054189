import { OnInit, Component, ViewEncapsulation, ViewChild, TemplateRef, OnDestroy, ChangeDetectorRef, Inject, HostListener, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../../services/device.service';
import { UiService } from '../../../services/ui.service';
import { NavbarService } from '../../../services/navbar.service';
import { ProductService } from '../../../services/product.service';
import { AuthService } from '../../../services/auth.service';
import { CompanyInstance } from '../../../models/company.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlsService } from '../../../services/controls.service';
import { ControlIstance } from '../../../models/control.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ProductInstance } from '../../../models/product.model';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { TaskIstance } from '../../../models/task.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TaskDialogComponent } from '../../ui/task-dialog/task-dialog.component';
import { TaskService } from '../../../services/task.service';
import { ControlSchedulingIstance } from '../../../models/control-scheduling.model';
import { ErrorService } from '../../../services/error.service';
import { ErrorInstance, ErrorTypeString, ErrorType } from '../../../models/error.model';
import { ControlSchedulingService } from '../../../services/controlScheduling.service';
import { DOCUMENT } from "@angular/common";
import { WINDOW } from '../../../services/window.service';
import { DeviceInstance } from '../../../models/device.model';
import { Utility } from '../../../utils/utility';
import {PageWithLoader} from "../page-with-loader";
import {ThemeService} from "../../../services/theme.service";

 
export interface ErrorModelForSelect extends ErrorInstance {
    selectLabel: string;
    errorTypeString: ErrorTypeString;
}

export interface WorkPorcessPhaseComputed {
    value: string,
    phaseId: number
}

@Component({
    selector: 'app-control',
    templateUrl: './control.component.html',
    styleUrls: ['./control.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ControlComponent extends PageWithLoader implements OnInit, OnDestroy {
    isAdmin: boolean;
    id: number;
    fixed = false;
    blocProduct = false;
    blocDevice = false;
    private sub: any;
    private selectedCompanyId: number;
    private selectedPlantId: number;
    control: ControlIstance = {};
    products: ProductInstance[];
    selectedProducts: ProductInstance[];
    filteredProducts: ProductInstance[] = [];
    devices: DeviceInstance[];
    devicesFullList: DeviceInstance[];
    selectedDevices: DeviceInstance[];
    filteredDevices: DeviceInstance[] = [];
    selectedError: ErrorInstance;
    errors: ErrorInstance[];
    errorsFullList: ErrorInstance[];
    errorGroupsList = [];
    lockRequest = true;
    selectSchedulings = [
        { id: 1, description: 'control.selectScheduling.start-device', type: 2, quantity: 0, cycle: false },
        { id: 2, description: 'control.selectScheduling.after-pieces', type: 1, quantity: 1, cycle: false },
        { id: 3, description: 'control.selectScheduling.every-pieces', type: 1, quantity: 1, cycle: true },
        { id: 4, description: 'control.selectScheduling.after-minutes', type: 2, quantity: 1, cycle: false },
        { id: 5, description: 'control.selectScheduling.every-minutes', type: 2, quantity: 1, cycle: true },
        { id: 6, description: 'control.selectScheduling.after-stop', type: 3, quantity: 1, errorId: null, cycle: false, repeat: 1 }
    ];

    tasks: TaskIstance[];
    controlSchedulings: ControlSchedulingIstance[];
    selectedCompany: CompanyInstance;
    editRow: number;
    filteredProductOptions: Observable<ProductInstance[]>;
    filteredDeviceOptions: Observable<DeviceInstance[]>;

    @ViewChild('orderHeader', { static: false }) orderHeaderTemplate: TemplateRef<any>;
    @ViewChild('order', { static: false }) orderTemplate: TemplateRef<any>;
    @ViewChild('question', { static: false }) questionTemplate: TemplateRef<any>;
    @ViewChild('enabled', { static: false }) enabledTemplate: TemplateRef<any>;
    @ViewChild('actions', { static: false }) actionsTemplate: TemplateRef<any>;
    @ViewChild('addItem', { static: false }) addItemTemplate: TemplateRef<any>;
    @ViewChild('description', { static: false }) descriptionField: ElementRef;
    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window,
        private _device: DeviceService,
        private _ui: UiService,
        private _products: ProductService,
        private _controls: ControlsService,
        private _navbar: NavbarService,
        private _scheduling: ControlSchedulingService,
        private _erros: ErrorService,
        private _tasks: TaskService,
        private _dialog: MatDialog,
        private _translate: TranslateService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _utility: Utility,
        public auth: AuthService,
        public cdRef: ChangeDetectorRef,
        _themeService: ThemeService
    ) {
      super(_themeService);
    }

    controlProductForm = new UntypedFormGroup({
        products: new UntypedFormControl('', []),
    });

    controlDeviceForm = new UntypedFormGroup({
        devices: new UntypedFormControl('', [])
    });

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const scroll = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
        if (scroll > 10) {
            this.fixed = true;
        } else if (this.fixed && scroll < 5) {
            this.fixed = false;
        }
    }


    async ngOnInit(): Promise<void> {

        this._translate.stream([
            'control.title',
        ]).subscribe(async (translations) => {
            this._navbar.setTitle(translations['control.title']);
        });

        this.sub = this._route.params.subscribe(params => {
            this.id = +params['id']; // (+) converts string 'id' to a
        });
        // get control
        this.control = await this._controls.get(this.id);

        // fill task array
        if (this.control.Tasks) {
            this.tasks = [...this.control.Tasks];
        }

        await this._initializeControlScheduling();

        // get logged company
        this.selectedCompanyId = this.control.companyId;

        // get logged plant
        this.selectedPlantId = this.control.plantId;

        // initializate errors
        await this._ngInitErrors();
        await this._ngInitProducts();
        await this._refreshProducts();
        await this._buildFilterProductOption();
        await this._ngInitDevices();

        this.lockRequest = false;

        // focus on description
        setTimeout(() => this.descriptionField.nativeElement.focus());
    }

    async _ngInitProducts() {
        // get products
        const deviceAttributes = ['id'];
        this.products = await this._products.getProducts(null, this.selectedPlantId, true, null, deviceAttributes);
        this.selectedProducts = this.control.Products.length ? [...this.control.Products] : this.control.allProducts
            ? this._utility.cloneArray(this.products) : [];
    }

    async _ngInitDevices() {
        // get device
        this.devicesFullList = await this._device.getDevicesByPlant(this.selectedCompanyId, this.selectedPlantId);
        await this._buildDevices();
    }

    private async _buildDevices() {
        this.devices = this._utility.cloneArray(this.devicesFullList);
        this.selectedDevices = this.control.Devices.length ? [...this.control.Devices] : this.control.allDevices
            ? this._utility.cloneArray(this.devices) : [];
        // Filter devices
        if (this.selectedError && this.selectedError.Devices && this.selectedError.Devices.length) {
            const selectedErrorDevicesIds = this.selectedError.Devices.map(d => d.id);

            if (selectedErrorDevicesIds.length <= 0) {
                // Reset devices list and put empty list in selectable devices
                this.selectedDevices = [];
                this.devices = [];
            } else {
                this.selectedDevices = this.selectedDevices.filter( (device) => selectedErrorDevicesIds.includes(device.id));
            }
        }

        this._refreshDevices();
        await this._buildFilterDeviceOption();
        if (this.selectedDevices.length > 0) {
            await this._buildErrors();
        }
    }

    async updateControl() {
        this.lockRequest = true;

        const schedules = this.controlSchedulings.filter(s => s.optionId);
        let error = false;

        if (schedules.length > 0) {

            schedules.forEach(s => {
                if (s.type === 3 && !s.errorId) error = true;
            });

            schedules.forEach(s => {
                delete s.optionId;
                delete s.companyId;
                delete s.createdAt;
                delete s.updatedAt;
                delete s.ControlSchedulingControls;
                delete s.error;
                delete s.WorkProcessPhase;
                if (!s.errorId) delete s.errorId;
                if (!s.phaseId) delete s.phaseId;
            });

            const schedulingData = {
                schedules,
                controlId: this.control.id,
                companyId: this.control.companyId,
                plantId: this.selectedPlantId
            };

            if (error) {
                this._ui.openSnackBar(this._translate.instant('control.saved-error'));
                this.lockRequest = false;
                return;
            }
            await this._scheduling.update(schedulingData);
        }

        const allProducts = this.selectedProducts.length === this.products.length ? true : false;
        const allDevices = this.selectedDevices.length === this.devices.length ? true : false;
        const productIds = allProducts ? [] : this.selectedProducts.map(p => p.id);
        const deviceIds = allDevices ? [] : this.selectedDevices.map(d => d.id);
        const tasks = {};

        for (const task of this.tasks) {
            tasks[task.id] = {
                enabled: task.ControlsTasks[0].enabled,
                order: task.ControlsTasks[0].order
            };
        }

        const data = {
            description: this.control.description,
            enabled: true,
            productIds,
            deviceIds,
            allProducts,
            allDevices,
            tasks,
            idCodeEnabled: this.control.idCodeEnabled
        };

        const res = await this._controls.update(this.control.id, data);
        if (res) {
            this.control = res;
            await this._initializeControlScheduling();
            this._ui.openSnackBar(this._translate.instant('control.saved-successfully'));
            this._router.navigate(['/quality-controls']);
        } else {
            this._ui.openSnackBar(this._translate.instant('control.saved-error'));
        }

        this.lockRequest = false;
    }

    // remove description with icon
    async removeDescription() {
        this.control.description = '';
    }

    /**
    * PRODUCT CONTROLLER
    */

    async addProduct(productId: number) {

        if (this.selectedDevices.length > 1
            && this.selectedDevices.length !== this.devices.length
            && this.selectedProducts.length !== 0) {
            this._ui.openSnackBar(this._translate.instant('control.add-product-info'));
            return;
        }

        this.blocProduct = true;

        const selectedProduct = this.products.find(p => p.id === productId);

        if (!this.selectedProducts.find(p => p.id === selectedProduct.id)) {
            this.selectedProducts.push(selectedProduct);
        }

        // refresh products dropdown list
        this._refreshProducts();
        await this._buildFilterProductOption();

        setTimeout(() => this.blocProduct = false, 1500);
    }

    async addAllProduct() {

        this.selectedProducts = [];
        this.selectedProducts = this._utility.cloneArray(this.products);

        // refresh products dropdown list
        this._refreshProducts();
        await this._buildFilterProductOption();
    }

    async removeProduct(productId: number) {

        this.selectedProducts = this.selectedProducts.filter(p => p.id !== productId);

        // refresh products dropdown list
        this._refreshProducts();
        await this._buildFilterProductOption();
    }

    async clearAllProducts() {

        this.selectedProducts = [];

        // refresh products dropdown list
        this._refreshProducts();
        await this._buildFilterProductOption();
    }

    /**
    * DEVICE CONTROLLER
    */

    async addDevice(deviceId: number) {

        if (this.selectedProducts.length > 1
            && this.selectedProducts.length !== this.products.length
            && this.selectedDevices.length !== 0) {
            this._ui.openSnackBar(this._translate.instant('control.add-device-info'));
            return;
        }

        this.blocDevice = true;

        const selectedDevices = this.devices.find(d => d.id === deviceId);

        if (!this.selectedDevices.find(d => d.id === selectedDevices.id)) {
            this.selectedDevices.push(selectedDevices);
        }

        // refresh products dropdown list
        this._refreshDevices();
        await this._buildFilterDeviceOption();
        await this._buildErrors();

        setTimeout(() => this.blocDevice = false, 1500);
    }

    async addAllDevices() {
        this.selectedDevices = [];
        this.selectedDevices = this._utility.cloneArray(this.devices);

        // refresh products dropdown list
        await this._refreshDevices();
        await this._buildFilterDeviceOption();
        await this._buildErrors();
    }

    async removeDevice(deviceId: number) {
        this.selectedDevices = this.selectedDevices.filter(d => d.id !== deviceId);

        // refresh products dropdown list
        await this._refreshDevices();
        await this._buildFilterDeviceOption();
        await this._buildErrors();

        this.resetSchedulingsErrorId();
    }

    async clearAllDevices() {
        this.selectedDevices = [];

        // refresh products dropdown list
        this._refreshDevices();
        await this._buildFilterDeviceOption();
        await this._buildErrors();

        this.resetSchedulingsErrorId();
    }

    private resetSchedulingsErrorId() {
        this.controlSchedulings.forEach( s => {
            if (s.errorId) s.errorId = null;
        });
    }

    /**
     * TASK CONTROLLER
     */

    async addTask() {

        const ref = this._dialog.open(TaskDialogComponent, {
            width: '700px',
            data: {
                controlId: this.control.id,
                companyId: this.selectedCompanyId,
                plantId: this.selectedPlantId
            }
        });

        ref.afterClosed().subscribe(async (result) => {
            if (result) {
                this.tasks.push(result);
                this._refreshTasks();
            }
        });

        this.tasks = [...this.tasks];
    }

    async deleteTask(taskId: number) {
        if (taskId) {
            const res = await this._tasks.delete(taskId, this.control.id);
            if (res.status === 200) {
                this.tasks = this.tasks.filter(t => t.id !== taskId);
                this._refreshTasks();
                this._ui.openSnackBar(this._translate.instant('control.deleted-successfully'));
            } else {
                this._ui.openSnackBar(this._translate.instant('control.deleted-error'));
            }
        }
    }

    async cloneTask(taskId: number) {
        if (taskId) {
            const task = this.tasks.find(t => t.id === taskId);
            const ref = this._dialog.open(TaskDialogComponent, {
                width: '700px',
                data: {
                    task: task,
                    controlId: this.control.id,
                    companyId: this.selectedCompanyId,
                    plantId: this.selectedPlantId,
                    isDuplicate: true
                }
            });

            ref.afterClosed().subscribe(async (result) => {
                if (result) {
                    this.tasks.push(result);
                    this._refreshTasks();
                }
            });

            this.tasks = [...this.tasks];
        }
    }

    async editTask(taskId: number) {
        if (taskId) {
            const task = this.tasks.find(t => t.id === taskId);
            const ref = this._dialog.open(TaskDialogComponent, {
                width: '700px',
                data: {
                    task: task,
                    controlId: this.control.id,
                    companyId: this.selectedCompanyId,
                    plantId: this.selectedPlantId,
                    isEdit: true
                }
            });

            ref.afterClosed().subscribe(async (result) => {
                if (result) {
                    const taskIndex = this.tasks.findIndex(t => t.id === taskId);
                    this.tasks[taskIndex] = result;
                    this._refreshTasks();
                }
            });

            this.tasks = [...this.tasks];
        }
    }

    async enablingTask(taskId: number) {
        this.tasks.forEach(t => {
            if (t.id === taskId) t.ControlsTasks[0].enabled = !t.ControlsTasks[0].enabled;
        });

    }
    /**
     * SCHEDULER CONTROLLER
     */

    changeOption(index: number) {
        const scheduling = this.controlSchedulings[index];
        const option = this.selectSchedulings.find(s => s.id === scheduling.optionId);

        this.controlSchedulings[index] = {
            optionId: option.id,
            type: option.type,
            quantity: option.quantity,
            cycle: option.cycle,
            repeat: 1
        };

        if (scheduling.id) this.controlSchedulings[index].id = scheduling.id;

        this.controlSchedulings = [...this.controlSchedulings];
    }

    addNewControlScheduling() {
        const temp: ControlSchedulingIstance = { optionId: null };
        this.controlSchedulings.push(temp);
    }

    async deleteControlScheduling(scheduling: ControlSchedulingIstance) {
        if (scheduling.id) {
            const res = await this._scheduling.delete(scheduling.id, this.control.id);
            if (res.status === 200) {
                this.controlSchedulings = this.controlSchedulings.filter(c => c.id !== scheduling.id);
            } else {
                this._ui.openSnackBar(this._translate.instant('control.deleted-scheduling-error'));
            }
        } else {
            this.controlSchedulings = this.controlSchedulings.filter(c => c !== scheduling);
        }

        if (this.controlSchedulings.length === 0) this.addNewControlScheduling();

        this.controlSchedulings = [...this.controlSchedulings];

        this.clearAllDevices();
    }

    isQuantityRequired(scheduling: ControlSchedulingIstance) {

        if (!scheduling.optionId) return false;
        else if (scheduling.optionId === 1) return false;
        else if (scheduling.optionId === 7) return false;
        else return true;
    }

    isRepeatRequired(scheduling: ControlSchedulingIstance) {

        if (!scheduling.optionId) return false;
        else if (scheduling.optionId === 6) return false;
        else return true;
    }

    async isStopRequired(scheduling: ControlSchedulingIstance) {

        if (!this.errors) {

        }

        if (!scheduling.optionId) return false;
        else if (scheduling.optionId === 6) {
            if (scheduling.errorId) this.selectedError = this.errors.find(e => e.id === scheduling.errorId);
            return true;
        } else return false;
    }

    private async _initializeControlScheduling() {
        // fill scheduling array
        if (this.control.ControlSchedulings) {
            this.controlSchedulings = [...this.control.ControlSchedulings];

            for (const schedule of this.controlSchedulings) {
                if (schedule.type === 3) schedule.optionId = 6;
                else if (schedule.type === 2 && schedule.cycle) schedule.optionId = 5;
                else if (schedule.type === 2 && !schedule.cycle && schedule.quantity > 0) schedule.optionId = 4;
                else if (schedule.type === 1 && schedule.cycle) schedule.optionId = 3;
                else if (schedule.type === 1 && !schedule.cycle) schedule.optionId = 2;
                else if (schedule.type === 4) schedule.optionId = 7;
                else schedule.optionId = 1;
            }

            if (this.controlSchedulings.length === 0) this.addNewControlScheduling();

        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    private _refreshTasks(): void {
        this.tasks = [...this.tasks];
    }

    private _refreshProducts(): void {
        // sort alphanumeric label
        this.selectedProducts.sort((a, b) => {
            const codeA = a.code.toUpperCase(); // ignore upper and lowercase
            const codeB = b.code.toUpperCase(); // ignore upper and lowercase

            if (codeA < codeB) return -1;
            if (codeA > codeB) return 1;
            return 0; // names must be equal
        });

        this.selectedProducts = [...this.selectedProducts];
        this.control.Products = [...this.selectedProducts];
        this.filteredProducts = this.products.filter(p => p !== this.selectedProducts.find(selected => selected.id === p.id));
    }

    private _refreshDevices(): void {
        // sort alphanumeric label
        this.selectedDevices.sort((a, b) => {
            const labelA = a.label.toUpperCase(); // ignore upper and lowercase
            const labelB = b.label.toUpperCase(); // ignore upper and lowercase

            if (labelA < labelB) return -1;
            if (labelA > labelB) return 1;
            return 0; // names must be equal
        });

        this.selectedDevices = [...this.selectedDevices];
        this.control.Devices = [...this.selectedDevices];

        // this.filteredDevices = this.devices.filter(d => d !== this.selectedDevices.find(selected => selected.id === d.id));
        const selectedIds = this.selectedDevices.map(d => d.id);
        this.filteredDevices = this.devices.filter(d => !selectedIds.includes(d.id));
    }

    private async _buildFilterProductOption() {
        this.filteredProductOptions = this.controlProductForm.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.products),
            map(name => name ? this._filterProduct(name) : this.filteredProducts.slice())
        );
    }

    private async _buildFilterDeviceOption() {
        this.filteredDeviceOptions = this.controlDeviceForm.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.devices),
            map(label => label ? this._filterDevice(label) : this.filteredDevices.slice())
        );
    }

    private _filterProduct(value: string): ProductInstance[] {
        const filterValue = value.toLowerCase();
        this._refreshProducts();
        return this.filteredProducts.filter(product =>
            (product.name.toLowerCase().indexOf(filterValue) === 0 || product.code.toLowerCase().indexOf(filterValue) === 0));
    }

    private _filterDevice(value: string): DeviceInstance[] {
        const filterValue = value.toLowerCase();
        this._refreshDevices();
        return this.filteredDevices.filter(device => device.label.toLowerCase().indexOf(filterValue) === 0);
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.tasks, event.previousIndex, event.currentIndex);

        for (const task of this.tasks) {
            task.ControlsTasks[0].order = this.tasks.indexOf(task) + 1;
        }
    }


    private async _ngInitErrors() {
        this.errorsFullList = await this._erros.getErrorsByCompany(this.selectedCompanyId, { populateErrorTag: true, populateDevices: true });
        // this.errorsFullList = this.errorsFullList.filter((e) => e.companyId === this.selectedCompanyId);
        await this._buildErrors();
    }

    private async _buildErrors() {
        this.errors = this._utility.cloneArray(this.errorsFullList);
        /**
         * Se sono selezionati 1 o più devices dalla rispettiva select,
         * bisogna filtrare il sottoinsieme di errori comuni a tutti i
         * device per evitare inconsistenze e che il controllo non sia
         * eseguito.
         */
        if (this.selectedDevices && this.selectedDevices.length > 0) {
            const selectedDevicesIds = this.selectedDevices.map(e => e.id);
            this.errors = this.errors.filter(err => {
                const errorDevIds = err.Devices.map( dd => dd.id);
                const accepted = selectedDevicesIds.filter(sdi => !errorDevIds.includes(sdi)).length <= 0;
                return accepted;
            });
        }
        await this.buildErrorsGroups();
    }

    private async buildErrorsGroups() {
        this.errorGroupsList = [];
        const unplannedPrefix = 'Unplanned';
        const plannedPrefix = 'Planned';
        const errorsList = this.errors;
        const errorsIds = new Set(errorsList.map( e => e.id));
        let errorsTagList = await this._erros.getTagsWithErrors(this.selectedCompanyId);

        errorsTagList = errorsTagList.filter((errorTag) => {
            return errorTag.Errors.some( e => errorsIds.has(e.id));
        });

        for (const tag of errorsTagList) {
            const planned = {
                id: tag.id,
                name: tag.name ? `${plannedPrefix} - ${tag.name}` : `${plannedPrefix} - Other`,
                Errors: []
            };
            const unplanned = {
                id: tag.id,
                name: tag.name ? `${unplannedPrefix} - ${tag.name}` : `${unplannedPrefix} - Other`,
                Errors: []
            };

            tag.Errors.sort(this.sortByText);

            for (const error of tag.Errors) {
                if (errorsIds.has(error.id)) {
                    if (error.type === 0) {
                        unplanned.Errors.push(error);
                    }
                    if (error.type === 1) {
                        planned.Errors.push(error);
                    }
                }
            }

            if (planned.Errors.length > 0) {
                planned.Errors = this._transformErrorsToErrorsForSelect(planned.Errors);
                this.errorGroupsList.push(planned);
            }
            if (unplanned.Errors.length > 0) {
                unplanned.Errors = this._transformErrorsToErrorsForSelect(unplanned.Errors);
                this.errorGroupsList.push(unplanned);
            }
        }

        this.errorGroupsList.sort(this.sortByName);
    }

    private sortByName(a, b) {
        if (a && b && a.name && b.name && a.name.toUpperCase() >= b.name.toUpperCase()) {
            return 1;
        } else {
            return -1;
        }
    }
    private sortByText(a, b) {
        if (a && b && a.text && b.text && a.text.toUpperCase() >= b.text.toUpperCase()) {
            return 1;
        } else {
            return -1;
        }
    }

    private _transformErrorsToErrorsForSelect(errors: ErrorInstance[]) {
        return errors.map((error: ErrorModelForSelect) => {
            const errorTypeString = (error.Category && error.Category.type === ErrorType.planned) ? 'Planned' : 'Unplanned';
            error.selectLabel = error.text;
            error.errorTypeString = errorTypeString;
            return error;
        });
    }

    async selectStopReason(e) {
        if (e && e.value) {
            const selectedStopReason = e.value;
            this.selectedError = this.errors.find(err => err.id === selectedStopReason) || null;
            await this._buildDevices();
        }
    }
}
