import { WorkShiftsInterface } from '../models/work-shift.model';
import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class WorkShiftsService {
    shifts: WorkShiftsInterface[];
    constructor(
        private _http: HttpService,
        private _config: ConfigService
    ) { }

    // Create new shift
    async createWorkShift(device: number, data: Object): Promise<WorkShiftsInterface> {
        const url = `${this._config.API.url}/device/${device}/workShift`;
        try {
            const response = await this._http.post<WorkShiftsInterface>(url, data);
            const shift = response.body;
            return shift;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    // Clone week shift
    async cloneWorkShift(device: number, data: Object) {
        const url = `${this._config.API.url}/device/${device}/cloneShift`;
        try {
            const response = await this._http.post<WorkShiftsInterface[]>(url, data);
            const shift = response.body;
            return shift;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    // Delete shift
    async deleteWorkShift(id: number, device: number) {
        const url = `${this._config.API.url}/device/${device}/workShift/${id}`;
        try {
            const response = await this._http.delete(url);
            return response.status;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    // Get all work shift given device
    async getWorkShift(device: number) {
        const url = `${this._config.API.url}/device/${device}/workShift`;
        try {
            const response = await this._http.get<WorkShiftsInterface[]>(url);
            const shifts = response.body;
            this.shifts = shifts.sort((a: WorkShiftsInterface, b: WorkShiftsInterface) => {
                const date1 = new Date(a.beginAt);
                const date2 = new Date(b.beginAt);
                return moment(date1).isBefore(moment(date2)) ? -1 : moment(date1).isAfter(moment(date2)) ? 1 : 0;
            });
            return this.shifts;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    // Update single work shift
    async updateWorkShift(device: number, id: number, data: Object) {
        const url = `${this._config.API.url}/device/${device}/workShift/${id}`;
        try {
            const response = await this._http.put<WorkShiftsInterface>(url, data);
            const shift = response.body;
            return shift;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    // Update shift in a given day
    async updateDayShift(device: number, data: Object) {
        const url = `${this._config.API.url}/device/${device}/updateShift`;
        try {
            const response = await this._http.put<WorkShiftsInterface[]>(url, data);
            const shifts = response.body;
            this.shifts = shifts.sort((a: WorkShiftsInterface, b: WorkShiftsInterface) =>  {
                const date1 = new Date(a.beginAt);
                const date2 = new Date(b.beginAt);
                return moment(date1).isBefore(moment(date2)) ? -1 : moment(date1).isAfter(moment(date2)) ? 1 : 0;
            });
            return shifts;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    // Update device attribute forceStop
    async updateForceStopDevice(device: number, data: boolean, attribute: string) {
        const url = `${this._config.API.url}/device/${device}/forceStop?${attribute}=${data ? 1 : 0}`;
        try {
            return await this._http.put(url);
        } catch (err) {
            console.log(err);
            return null;
        }
    }

}

