import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { UserInstance } from '../models/user.model';
import { OperatorInstance } from '../models/operator.model';


@Injectable()
export class OperatorService {

    constructor(private _http: HttpService, private _config: ConfigService) { }

    async getOperators(plantId?: number) {
        const url = `${this._config.API.url}/operators?plantId=${plantId}`;

        const response = await this._http.get<OperatorInstance[]>(url);

        return response.body;
    }

    async createOperator(name: string, surname: string, code: string, plantId: number, deviceIds?: number[]) {
        const url = `${this._config.API.url}/operator`;

        try {
            const operatorData = {
                name: name,
                surname: surname,
                code: code || null,
                plantId: plantId,
                allDevices: false,
                devices: deviceIds
            };
            const response = await this._http.post<OperatorInstance>(url, operatorData);
            const res = response.body;
            return res;

        } catch (err) {
            return null;
        }
    }

    async updateOperator(operatorId: number, name: string, surname: string, code: string, deviceIds: number[]) {
        const url = `${this._config.API.url}/operator/${operatorId}`;
        try {

            const operatorData = {
                name: name,
                surname: surname,
                code: code || null,
                allDevices: false,
                devices: deviceIds
            };

            const response = await this._http.put<OperatorInstance>(url, operatorData);
            const res = response.body;
            return res;

        } catch (err) {
            return null;
        }
    }

    async deleteOperator(operatorId: number) {
        const url = `${this._config.API.url}/operator/${operatorId}`;
        const response = await this._http.delete(url);

        return response.status;
    }
}
