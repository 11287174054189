import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceInstance } from '../../../models/device.model';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { OperatorInstance } from '../../../models/operator.model';


@Component({
    selector: 'app-operators-dialog',
    templateUrl: './operators-dialog.component.html',
    styleUrls: ['./operators-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OperatorsDialogComponent implements OnInit {
    operator: OperatorInstance;
    devices: DeviceInstance[] = [];
    devicesControl = new UntypedFormControl();


    constructor(
        private _ref: MatDialogRef<OperatorsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { operator?: OperatorInstance, plantDevices: DeviceInstance[] },
        private _translate: TranslateService,
    ) { }

    async ngOnInit() {
        this.operator = this.cloneObject(this.data.operator);
        this.devices = this.clone(this.data.plantDevices);

        if (this.operator.Devices) {
            this.devicesControl.patchValue(this.operator.Devices);
        }
    }

    onNoClick() {
        this._ref.close();
    }

    closeDialog() {
        this._ref.close();
    }

    confirm(mode?: string) {
        if (mode === 'save') {
            this.operator.Devices = this.getSelectedDevices(this.devicesControl.value);
            this._ref.close(this.operator);
        } else {
            this._ref.close(null);
        }
    }

    rowIdentityComparator(row: DeviceInstance) {
        return row.id;
    }


    clone(array: any) {
        return array.map(x => Object.assign({}, x));
    }

    cloneObject(object: any) {
        return Object.assign({}, object);
    }

    compareObjects(o1: any, o2: any): boolean {
        if (!o1 || !o2) {
            return false;
        }
        return o1.id === o2.id;
    }

    getSelectedDevices(selectedDevices) {
        return this.devices.filter(d => (selectedDevices.map(el => el.id)).includes(d.id));
    }
}
