import { Injectable } from '@angular/core';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class ConfigService {
    public API: {
        url: string,
        urlV2: string
    };

    constructor(private _envSettings: EnvConfigurationService) {
        this.API = {
            url: this._envSettings.settings.api.url,
            urlV2: this._envSettings.settings.api.urlV2
        };
    }
}
