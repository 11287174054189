import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { ProductsFamilyInstance } from '../../../models/productsFamily.model';

@Component({
    selector: 'app-families-list-cell',
    templateUrl: './families-list-cell.component.html',
    styleUrls: ['./families-list-cell.component.scss'],
})
export class FamiliesListCellComponent implements OnInit {
    @Input() tplKey: any;
    @Input() data: any;

    @Output() editFamilyClicked = new EventEmitter<ProductsFamilyInstance>();
    @Output() deleteFamilyClicked = new EventEmitter<ProductsFamilyInstance>();

    showEllipsisTooltip: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    
    pencilClick(family: ProductsFamilyInstance) {
        this.editFamilyClicked.emit(family);
    }

    trashClick(family: ProductsFamilyInstance) {
        this.deleteFamilyClicked.emit(family);
    }
}