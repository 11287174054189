import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { TagInstance } from '../models/tag.model';


@Injectable()
export class TagsService {
    tags: TagInstance[];


    constructor(private _http: HttpService, private _config: ConfigService) { }

    async getTags(populateErrors?: boolean, companyId?: number) {
        let url = `${this._config.API.url}/errorsTag?`;
        if (populateErrors) {
            url += `&populateErrors=` + populateErrors;
        }
        if (companyId) {
            url += `&companyId=` + companyId;
        }
        const response = await this._http.get<TagInstance[]>(url);

        this.tags = response.body;
        return this.tags;
    }

    async deleteTag(tagId: number) {
        const url = `${this._config.API.url}/errorsTag/${tagId}`;
        const response = await this._http.delete(url);

        return response.status;
    }

    async create(tag: TagInstance) {
        const url = `${this._config.API.url}/errorsTag`;
        const response = await this._http.post<TagInstance>(url, {
            name: tag.name,
            companyId: tag.companyId
        });

        return response.body;
    }

    async update(tag: TagInstance) {
        const url = `${this._config.API.url}/errorsTag/${tag.id}`;


        const response = await this._http.put<TagInstance>(url, {
            name: tag.name
        });

        return response.body;
    }
}

