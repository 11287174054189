import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { HttpService } from "./http.service";
import { ProductionUnitsInstance } from "../models/production-units.model"

@Injectable({
    providedIn: 'root'
})
export class ProductionUnitsService {
    private readonly _baseUrl = 'productionUnits';

    constructor(
        private _http: HttpService,
        private _config: ConfigService,
    ) { }

    async getUnit(id: number): Promise<ProductionUnitsInstance> {
        let url = `${this._config.API.urlV2}/${this._baseUrl}/${id}`;
        const response = await this._http.get<ProductionUnitsInstance>(url);
        return response.body;
    }

    async getUnits(companyId: number): Promise<ProductionUnitsInstance[]> {
        let url = `${this._config.API.urlV2}/${this._baseUrl}/company/${companyId}`;
        const response = await this._http.get<ProductionUnitsInstance[]>(url);
        return response.body;
    }

    async deleteUnit(id: number): Promise<void> {
        let url = `${this._config.API.urlV2}/${this._baseUrl}/${id}`;
        await this._http.delete<ProductionUnitsInstance[]>(url);
    }

    async createUnit(unit: ProductionUnitsInstance) {
        let url = `${this._config.API.urlV2}/${this._baseUrl}`;
        await this._http.post<ProductionUnitsInstance>(url, unit);
    }

    async updateUnit(unit: ProductionUnitsInstance) {
        let url = `${this._config.API.urlV2}/${this._baseUrl}/${unit.id}`;
        await this._http.put<ProductionUnitsInstance>(url, unit);
    }
};
