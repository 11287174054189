import { HttpService } from './http.service';
import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { ApiWorkData, ApiWorkPlantData, WorkInstance, WorksPlantsInstance, WorksPlantsViewInstance } from '../models/work.model';


@Injectable()
export class WorksService {

    constructor(private _http: HttpService, private _config: ConfigService) { }

    async getWorks(companyId: number , plantId?: number) {
        let url = `${this._config.API.urlV2}/works?companyId=${companyId}`;

        if (plantId) { url += `&plantId=${plantId}`; }
        
        const response = await this._http.get<WorksPlantsViewInstance[]>(url);
        return response.body;
    }

    async getWorksForCompany(companyId: number) {
        let url = `${this._config.API.urlV2}/works/company/${companyId}`;
        
        const response = await this._http.get<WorkInstance[]>(url);
        return response.body;
    }

    async getWorksForPlant(plantId: number) {
        let url = `${this._config.API.urlV2}/works/plant/${plantId}`;
        
        const response = await this._http.get<WorksPlantsViewInstance[]>(url);
        return response.body;
    }

    async getWorkForPlantAndCode(plantId: number, code: string) {
        let url = `${this._config.API.urlV2}/works/plant/${plantId}/code/${code}`;
        
        const response = await this._http.get<WorkInstance>(url);
        return response.body;
    }

    async createWork(data: ApiWorkData) {
        let url = `${this._config.API.urlV2}/works`;
        
        const response = await this._http.post<WorkInstance>(url, data);
        return response.body;
    }

    async updateWork(id: number, data: ApiWorkData ) {
        let url = `${this._config.API.urlV2}/works/${id}`;
        
        const response = await this._http.put<WorkInstance>(url, data);
        return response.body;
    }

    async createWorkPlant(data: ApiWorkPlantData) {
        let url = `${this._config.API.urlV2}/works/plant`;
        
        const response = await this._http.post<WorksPlantsInstance>(url, data);
        return response.body;
    }

    async updateWorkPlant(id: number, data: ApiWorkPlantData) {
        let url = `${this._config.API.urlV2}/works/plant/${id}`;
        
        const response = await this._http.put<WorksPlantsInstance>(url, data);
        return response.body;
    }

    async deleteWorkPlant(id: number) {
        let url = `${this._config.API.urlV2}/works/plant/${id}`;
        
        const response = await this._http.delete<WorksPlantsInstance>(url);
        return response.body;
    }

}
