import { OrderCoreInstance } from "./order-core.model";
import { OrderStatus } from "./order-device.model";
import { OrdersRankInstance } from "./orders-rank.model";
import { PlantInstance } from "./plant.model";

export enum QuantityDistribution {
    equal = 1,
    proportional = 2,
    fill = 3,
    none = 4
}

export enum TimeDistribution {
    equal = 1,
    proportional = 2
}

export interface OrderGroupsInstance {
    id?: number;
    code?: string;
    target?: number;
    date?: Date;
    quantityDistribution?: QuantityDistribution;
    timeDistribution?: TimeDistribution;
    jsonInfo?: Object;
    status?: OrderStatus;
    plantId?: number;
    Plant?: PlantInstance;
    OrdersRank?: OrdersRankInstance;
    Orders?: OrderCoreInstance[];
}