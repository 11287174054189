import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { ProductInstance, ProductInterface } from '../../..//models/product.model';

@Component({
    selector: 'app-products-list-cell',
    templateUrl: './products-list-cell.component.html',
    styleUrls: ['./products-list-cell.component.scss'],
})
export class ProductsListCellComponent implements OnInit {
    @Input() tplKey: any;
    @Input() data: any;
    @Input() optionsProductIdSelected: number;

    @Output() infoProductClicked = new EventEmitter<ProductInterface>();
    @Output() editProductClicked = new EventEmitter<ProductInterface>();
    @Output() optionsProductClicked = new EventEmitter<ProductInterface>();
    @Output() duplicateProductClicked = new EventEmitter<ProductInterface>();
    @Output() deleteProductClicked = new EventEmitter<ProductInterface>();

    showEllipsisTooltip: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        if(this.data.id == this.optionsProductIdSelected){
            return;
        }
        if (changes['optionsProductIdSelected'] && changes['optionsProductIdSelected'].currentValue !== changes['optionsProductIdSelected'].previousValue) {
            this.showEllipsisTooltip = false;
        }
   }

    drawerClick(product: ProductInstance) {
        this.infoProductClicked.emit(product);
    }
    
    pencilClick(product: ProductInstance) {
        this.editProductClicked.emit(product);
    }
    
    ellipsisClick(product: ProductInstance) {
        this.showEllipsisTooltip = !this.showEllipsisTooltip;
        this.optionsProductClicked.emit(product);
    }

    duplicateProductClick(product: ProductInstance) {
        this.duplicateProductClicked.emit(product);
    }

    deleteProductClick(product: ProductInstance) {
        this.deleteProductClicked.emit(product);
    }
}