import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { UserRole } from '../../../models/user.model';

import { SidebarService } from '../../../services/sidebar.service';
import { AuthService } from '../../../services/auth.service';
import { NavbarService } from '../../../services/navbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ViewService, UserView } from '../../../services/view.service';
import {ThemeService} from "../../../services/theme.service";
import { CompanyService } from '../../../services/company.service';
import { CompanyInstance } from '../../../models/company.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
    private control_details = '/details';
    private _filterSubscription;

    companies: CompanyInstance[];
    companiesControl = new FormControl();
    selectedCompany: CompanyInstance;
    companiesList: string[] = [];
    filteredCompanies: Observable<string[]>;
    company: string;


    languages = [
        { code: 'en', locale: 'en-US', label: 'English' },
        { code: 'it', locale: 'it-IT', label: 'Italiano' },
        { code: 'de', locale: 'de-DE', label: 'Deutsch' },
        { code: 'fr', locale: 'fr-FR', label: 'Français' },
        { code: 'el', locale: 'el-GR', label: 'Ελληνικά' }
    ];

    currentLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';

    showMenu = false;

    isAdmin = false;
    isOrdersCore = false;
    isManager = false;
    isOrdersManager = true; // TODO implement module validation
    isChildPage: boolean;
    isWorkProcessManager: boolean = false;

    constructor(
        public sidebar: SidebarService,
        private _router: Router,
        public _auth: AuthService,
        public navbar: NavbarService,
        public _translate: TranslateService,
        public _viewService: ViewService,
        public _company: CompanyService,
        private _themeService: ThemeService
    ) { 
    }

    async ngOnInit() {
        this._filterSubscription = this._auth.onNavbarChange.subscribe(() => {
            this.navbarChange();
        });

        if (!!this._auth && !!this._auth.user) {
            this.isAdmin = this._auth.user.role === UserRole.admin;
            this.isManager = this._auth.user.role === UserRole.manager;

            this.isOrdersManager = this.isAdmin || true; // TODO: implement module validation
            
            // this._viewService.userCanView(this._auth.user, UserView.ordersCore)
            //                 .then( userCanViewOrdersCore => {
            //                     this.isOrdersCore = userCanViewOrdersCore;
            //                 });
            this._viewService.userCanView(this._auth.user, UserView.workProcess)
                            .then( userCanViewWorkProcess => {
                                this.isWorkProcessManager = userCanViewWorkProcess;
                            });
            if(this.isAdmin){
                this.initCompanyList();                           
            }
        }
        this._router.events.subscribe(() => {
            // see also
            this.isControlPage();
        });
    }

    private async fetchCompanies() {
        this.companies = await this._company.getCompanies();
    }

    private async initCompanyList() {
            this.filteredCompanies = this.companiesControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );                
            await this.fetchCompanies();
            if (this.companies.length > 0) {
                this.companiesList = this.companies.map(c => c.name);
                const selectedCompanyId = Number(localStorage.getItem('companyId'));
                if(selectedCompanyId){
                    this.selectedCompany = this.companies.find(c => c.id == selectedCompanyId);
                } else {
                    this.selectedCompany = this.companies[0];
                }
                this.companiesControl.setValue(this.selectedCompany.name);
                localStorage.setItem('companyId', this.selectedCompany.id.toString());
                this.company = this.selectedCompany.name;
            }
    }

    onCompanySelected( event: MatAutocompleteSelectedEvent){
        const companyName = event.option.value;
        if(companyName == this.company) return;
        this.company = companyName;
        const company = this.companies.find(c => c.name == companyName);
        localStorage.setItem('companyId', company.id.toString());
        this._company.emitChangeCompany(company);
    }

    clone(array: any[]) {
        return array.map(x => Object.assign({}, x));
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.companiesList.filter(company => company.toLowerCase().includes(filterValue));
    }

    ngOnDestroy() {
        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }
    }

    /**
     * Check if the current page is the home page
     *
     * @memberof FilterBarComponent
     */
    public isLoginPage(): boolean {
        return this._router.isActive('/login', true);
    }

    setLanguage(locale: string) {
        if (this._translate.currentLang === locale) {
            return;
        }

        localStorage.setItem('lang', locale);
        window.location.reload();
    }

    logout() {
        this.selectedCompany = null;
        this._auth.logout();
    }
    toggleMenu() {
        this.showMenu = !this.showMenu;
    }

    isControlPage() {
        const url = this._router.url;
        this.isChildPage = url.includes(this.control_details);
    }

    goBack() {
        this._router.navigate(['/quality-controls']);
    }

    private navbarChange() {
        this.selectedCompany = null;
        this.isAdmin = this._auth.user.role === UserRole.admin;
        // this.isOrdersCore = this.isAdmin || this._auth.user.role === UserRole.manager;
        this.isManager = this._auth.user.role === UserRole.manager;
        this.isOrdersManager = this.isAdmin || true;  // TODO: implement module validation

        // this._viewService.userCanView(this._auth.user, UserView.ordersCore)
        //                     .then( userCanViewOrdersCore => {
        //                         this.isOrdersCore = userCanViewOrdersCore;
        //                     });
        this._viewService.userCanView(this._auth.user, UserView.workProcess)
                            .then( userCanViewWorkProcess => {
                                this.isWorkProcessManager = userCanViewWorkProcess;
                            });
        if(this.isAdmin){
            this.initCompanyList();
        }                    
    }
}
