import { Component, Input, OnInit, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Drawer, NotificationConfig, NotificationService } from '@vapor/angular-ui';
import { TranslateService } from '@ngx-translate/core';
import { OrderStatus } from '../../../models/order-device.model';
import { OrderGroupsInstance, QuantityDistribution, TimeDistribution } from '../../../models/order-group.model';
import { OrderListInterface } from '../../../models/orders-groups-view.model';
import { OrdersListService } from '../../../services/ordersList.service';
import * as moment from 'moment';

@Component({ 
    selector: 'app-order-group-info',
    templateUrl: './order-group-info.component.html',
    styleUrls: ['./order-group-info.component.scss']
})
export class OrderGroupInfoComponent implements OnInit, OnChanges { 

    @ViewChild('content', { static: false }) contentTemplate: TemplateRef<any>;
    @ViewChild('bottomBar', { static: false }) bottomBarTemplate: TemplateRef<any>;
    @ViewChild('headerTemplate', { static: false }) headerTemplate: TemplateRef<any>;

    tabs = []; 
    selectedTabId: number;
    plannedStatuses = [];
    productionStatuses = [];
    quantityDistributionArray = [];
    timeDistributionArray = [];
    newOrderGroupStatus: OrderStatus;
    @Input() orderGroup?: OrderListInterface;
    @Input() toBeProduced?: number;

    constructor(
        private readonly _translate: TranslateService,
        private _ordersListService: OrdersListService,
        private readonly _notification: NotificationService,
    ) { }

    get submitDisabled(): boolean {
        return this.newOrderGroupStatus === this.orderGroup?.status;
    }

    ngOnInit(): void {
        this._translate.stream([
            'orders-list.status.draft',
            'orders-list.status.planned',
            'orders-list.status.running',
            'orders-list.status.suspended',
            'orders-list.status.completed',
            'orders-list.orderInfo.equal',
            'orders-list.orderInfo.proportional',
            'orders-list.orderInfo.fill',
            'orders-list.orderInfo.none',
            'orders-list.orderStatus.registry',
            'orders-list.orders',
        ]).subscribe((translations) => {

            this.tabs = [{
                id: 1,
                text: translations['orders-list.orderStatus.registry'],
                disabled: false,
            }, {
                id: 2,
                text: translations['orders-list.orders'],
                disabled: false,
            }];
            this.selectedTabId = 1;
            this.plannedStatuses = [
                { id: OrderStatus.draft, val: translations['orders-list.status.draft'] },
                { id: OrderStatus.planned, val: translations['orders-list.status.planned'] },
            ];
            this.productionStatuses = [
                { id: OrderStatus.running, val: translations['orders-list.status.running'] },
                { id: OrderStatus.suspended, val: translations['orders-list.status.suspended'] },
                { id: OrderStatus.completed, val: translations['orders-list.status.completed'] },
            ];
            this.quantityDistributionArray = [
                {
                    id: QuantityDistribution.equal,
                    label: translations['orders-list.orderInfo.equal'],
                },
                {
                    id: QuantityDistribution.proportional,
                    label: translations['orders-list.orderInfo.proportional'],
                },
                {
                    id: QuantityDistribution.fill,
                    label: translations['orders-list.orderInfo.fill'],
                },
                {
                    id: QuantityDistribution.none,
                    label: translations['orders-list.orderInfo.none'],
                },
            ];
            this.timeDistributionArray = [
                {
                    id: TimeDistribution.equal,
                    label: translations['orders-list.orderInfo.equal'],
                },
                {
                    id: TimeDistribution.proportional,
                    label: translations['orders-list.orderInfo.proportional'],
                }
            ];
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.orderGroup?.currentValue?.status) {
            const currentValue = changes.orderGroup.currentValue;
            this.newOrderGroupStatus = currentValue.status;
            if (this.productionStatuses.length == 3) {
                this.productionStatuses[0].disabled = currentValue.status != OrderStatus.running;
                this.productionStatuses[1].disabled = currentValue.status == OrderStatus.running;
                this.productionStatuses[2].disabled = currentValue.status === OrderStatus.running;
            }
        }
    }

    async editClicked() {
        if(this.newOrderGroupStatus != this.orderGroup.status){
            try {
                const result = await this._ordersListService.editOrderGroupStatus(this.orderGroup.id, this.newOrderGroupStatus);
                if(String(result).charAt(0) !== '2'){
                    this._translate.stream([
                        'orders-list.orderInfo.editOrderStatusError',
                    ], { orderCode: this.orderGroup.code}).subscribe((translations) => {
                        const config: NotificationConfig = {
                            content: translations['orders-list.orderInfo.editOrderStatusError'],
                            type: 'toast',
                            style: 'error', 
                            timeout: 5000,
                            position: 'right',
                        };
                        this._notification.show(config);
                    });
                } else {
                    this._translate.stream([
                        'orders-list.orderInfo.editOrderStatusMessage',
                    ], { orderCode: this.orderGroup.code}).subscribe((translations) => {
                        const config: NotificationConfig = {
                            content: translations['orders-list.orderInfo.editOrderStatusMessage'],
                            type: 'toast',
                            style: 'check',
                            timeout: 5000,
                            position: 'right',
                        };
                        this._notification.show(config);
                        this.orderGroup.status = this.newOrderGroupStatus;
                    });
                }
            } catch (error) {
                throw new Error('Error editing group state');
            }
        }
    }


    concatDevices(devices: any[]){
        return devices.reduce((res,dev) => res + '\n\r' + dev.label ,'')
    }

    plannedStatusChanged(status: OrderListInterface): void{
        this.newOrderGroupStatus = status.id;
    }

    productionStatusChanged(status: OrderListInterface): void{
        this.newOrderGroupStatus = status.id;
    }

    parseDate(date: any): string {
        const local = localStorage.getItem('lang') || 'en';
        return local === 'en' ? moment(date).format('MM/DD/YYYY HH:mm') : moment(date).format('DD/MM/YYYY HH:mm');
    }

    toJSON(data: string){
        try {
            const values = JSON.parse(data);
            for(let key in Object.keys(values)){
                if(key != "") return values; 
            }
            return null;
        } catch (error) {
            console.log(error)
            return null;
        }
    }
}
