import { Injectable } from '@angular/core';

import { HttpService } from './http.service';
import { ConfigService } from './config.service';
import { DepartmentResource, Resource, ResourceTree, ResourceType, WorkCenterResource } from '../models/resources.model';

@Injectable({
    providedIn: 'root'
})
export class ResourcesService {
    private readonly _baseUrl = 'resources';

    constructor(
        private _http: HttpService,
        private _config: ConfigService,
    ) { }

    async getResources(plantId: number) : Promise<ResourceTree> {
        let url = `${this._config.API.urlV2}/${this._baseUrl}/${plantId}`;
        const response = (await this._http.get<ResourceTree>(url)).body;
        return response;
    }

    async newWorkCenter(plantId: number, code: string, description: string, departmentId: number, deviceIds: number[] | null) : Promise<WorkCenterResource> {
        
        let url = `${this._config.API.urlV2}/workCenters`;

        const data = {
            plantId,
            code,
            description,
            departmentId,
            deviceIds
        }

        const response = (await this._http.post<WorkCenterResource>(url, data)).body;
        return response;
    }

    async newDepartment(plantId: number, code: string, description: string, deviceIds: number[] | null) : Promise<DepartmentResource> {
        let url = `${this._config.API.urlV2}/departments`;
        const data = {
            plantId,
            code,
            description,
            deviceIds
        }

        const response = (await this._http.post<DepartmentResource>(url, data)).body;
        return response;
    }

    async editWorkCenter(workCenterId: number, departmentId: number,  code: string, description: string, deviceIds: number[] | null) : Promise<WorkCenterResource> {
        let url = `${this._config.API.urlV2}/workCenters/${workCenterId}`;

        const data = {
            departmentId,
            code,
            description,
            deviceIds
        }

        const response = (await this._http.put<WorkCenterResource>(url, data)).body;
        return response;
    }

    async editDepartment(departmentId: number, code: string, description: string, deviceIds: number[] | null) : Promise<DepartmentResource> {
        let url = `${this._config.API.urlV2}/departments/${departmentId}`;

        const data = {
            code,
            description,
            deviceIds
        }

        const response = (await this._http.put<DepartmentResource>(url, data)).body;
        return response;
    }


}