import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { configUiLocalization } from '@vapor/angular-ui-extra/locale';

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    private currencies = [
      { code: 'en', currency: 'USD' },
      { code: 'it', currency: 'EUR' },
      { code: 'de', currency: 'EUR' },
      { code: 'fr', currency: 'EUR' },
      { code: 'el', currency: 'EUR' },
    ];

    constructor(
      private _translate: TranslateService
    ) {
        const lang = localStorage.getItem('lang');

        this._translate.setDefaultLang(lang || 'en');
        this._translate.use(lang || 'en');

        configUiLocalization(lang, this.currencies[lang || 'en']);
    }
    ngOnInit() {

    }
}
