import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map } from 'rxjs';

@Pipe({
    name: 'translateJSON',
    pure: false
})
export class JsonTranslatorPipe implements PipeTransform {
    constructor(private _translate: TranslateService) {}

    transform(items: { [key: string]: string }, params?: object): any {
        
        if (!items || Object.keys(items).length === 0) {
            return items;
        }
        
        const keys = Object.keys(items);  
        const values = keys.map(key => items[key]);

        let translationsObservable: any;
        if(params) {
            translationsObservable = forkJoin(
            values.map(value => this._translate.get(value, {...params}))
        )}else {
            translationsObservable = forkJoin(
                values.map(value => this._translate.get(value))
            )
        }

        return translationsObservable.pipe(
            map(translations => {
                const transformed = {};
                keys.forEach((key, index) => {
                    transformed[key] = translations[index];
                });
                return transformed;
            })
        );
    }
}
