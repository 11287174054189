import { HttpService } from './http.service';
import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { OrderInstance, OrderInstanceExtended } from '../models/order.model';
import { ProductInstance } from '../models/product.model';
import { ApiOrderOperationData } from '../models/operation.model'


@Injectable()
export class OrdersService {

    constructor(private _http: HttpService, private _config: ConfigService) { }

    async get(satelliteUrl?: string) {
        let url: string;
        if (satelliteUrl) url = satelliteUrl + '/ordersList';
        else url = localStorage.getItem('satelliteUrl') + '/ordersList';

        const response = await this._http.get<OrderInstance[]>(url);
        return response.body;

    }

    async getProducts(satelliteUrl?: string) {
        let url: string;
        if (satelliteUrl) url = satelliteUrl + '/products';
        else url = localStorage.getItem('satelliteUrl') + '/products';

        const response = await this._http.get<ProductInstance[]>(url);
        return response.body;

    }

    async delete(code: string, satelliteUrl?: string) {
        let url: string;
        if (satelliteUrl) url = satelliteUrl + '/order/' + code;
        else url = localStorage.getItem('satelliteUrl') + '/order/' + code;

        const response = await this._http.put(url);
        return response.status;
    }

    async create(data: any, satelliteUrl?: string) {
        let url: string;
        if (satelliteUrl) url = satelliteUrl + '/order';
        else url = localStorage.getItem('satelliteUrl') + '/order';

        try {
            const response = await this._http.post(url, data);
            return response.status;
        } catch (err) {
            return err.status;
        }
    }

    async createOrderOperationV2(data: ApiOrderOperationData) {
        let url = `${this._config.API.urlV2}/orderOperation`;
        
        const response = await this._http.post<OrderInstanceExtended>(url, data);
        return response.body;
    }

    async editOrderOperationV2(orderId: number, data: ApiOrderOperationData) {
        let url = `${this._config.API.urlV2}/orderOperation/${orderId}`;
        
        const response = await this._http.put<OrderInstanceExtended>(url, data);
        return response.body;
    }

    async getOrderOperationV2(orderId: number) {
        let url = `${this._config.API.urlV2}/orderOperation/${orderId}`;
        
        const response = await this._http.get<OrderInstanceExtended>(url);
        return response.body;
    }
}
