import { HttpService } from './http.service';
import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { OperationInstance, OperationsViewInstance } from '../models/operation.model';


@Injectable()
export class OperationsService {

    constructor(private _http: HttpService, private _config: ConfigService) { }

    async getOperationsOrder(orderId: number) {
        let url = `${this._config.API.urlV2}/operations/orders/${orderId}/fetchAll`;
        
        const response = await this._http.get<OperationInstance[]>(url);
        return response.body;
    }

    async getOperationsDevice(deviceId: number, status: number) {
        let url = `${this._config.API.urlV2}/operations/device/${deviceId}/fetchAll?status=${status}`;
        
        const response = await this._http.get<OperationsViewInstance[]>(url);
        return response.body;
    }

    async getOperationDevice(deviceId: number, operationId: number, objectProgresses: string) {
        let url = `${this._config.API.urlV2}/operations/device/${deviceId}/operation/${operationId}`;

        if (objectProgresses) { url += `?objectProgresses=${objectProgresses}`; }
        
        const response = await this._http.get<OperationInstance>(url);
        return response.body;
    }

}
