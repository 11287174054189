import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UiService } from '../../../services/ui.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';

import { TranslateService } from '@ngx-translate/core';
import { TagsService } from '../../../services/tag.service';
import { TagInstance } from '../../../models/tag.model';
import { DialogComponent } from '../../ui/dialog/dialog.component';
import { CompanyService } from '../../../services/company.service';
import { CompanyInstance } from '../../../models/company.model';
import { AuthService } from '../../../services/auth.service';
import { UserRole } from '../../../models/user.model';
import { DeviceService } from '../../../services/device.service';
import { DeviceInstance } from '../../../models/device.model';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TagsComponent implements OnInit {
    companies: CompanyInstance[];
    devices: DeviceInstance[];
    selectedCompany: CompanyInstance;
    selectedCompanyId: number;
    tagsList: TagInstance[];
    tmpTags: TagInstance[];
    columns = [];
    modified: boolean;
    editRow: number;
    isAdmin = false;

    @ViewChild('label', { static: true }) labelTemplate: TemplateRef<any>;
    @ViewChild('stops', { static: true }) stopsTemplate: TemplateRef<any>;
    @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
    @ViewChild('addItem', { static: true }) addItemTemplate: TemplateRef<any>;

    constructor(
        private _company: CompanyService,
        private _device: DeviceService,
        private _ui: UiService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _dialog: MatDialog,
        private _translate: TranslateService,
        private _tags: TagsService,

        public auth: AuthService,
    ) { }

    async ngOnInit() {
        this._translate
            .stream([
                'tags.title',
                'devices.columns.name',
                'tags.columns.n_errors'
            ])
            .subscribe((translations) => {
                this._navbar.setTitle(translations['tags.title']);
                setTimeout(() => this._sidebar.selected = 'tags');
                this.columns = [{
                    resizable: false,
                    canAutoResize: true,
                    width: 400,
                    maxWidth: 400,
                    name: translations['devices.columns.name'],
                    prop: 'name',
                    cellTemplate: this.labelTemplate
                }, {
                    resizable: false,
                    canAutoResize: true,
                    width: 250,
                    maxWidth: 250,
                    name: translations['tags.columns.n_errors'],
                    prop: 'Errors.length',
                    cellTemplate: this.stopsTemplate
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 160,
                    name: '',
                    headerTemplate: this.addItemTemplate,
                    cellTemplate: this.actionsTemplate
                }];
            });
        this.isAdmin = this.auth.user.role === UserRole.admin;
        if (this.isAdmin) {
            this.companies = await this._company.getCompanies();
            this.selectedCompanyId = Number(localStorage.getItem('companyId'));

            if (this.selectedCompanyId) {
                this.selectedCompany = this.companies.find((company) => company.id === this.selectedCompanyId);
                await this.companyChanged();
            } else if (this.companies.length > 0) {
                this.selectedCompany = this.companies[0];
                await this.companyChanged();
            }
        } else {
            this.devices = await this._device.getDevices();
            if (this.devices.length > 0) {
                this.selectedCompany = this.devices[0].Company;
                this.tagsList = await this._tags.getTags(true);
                this.tagsList = this.tagsList.filter((tag) => {
                    return tag.id > 0;
                });
                this.tmpTags = this.clone(this.tagsList);
                this.editRow = null;
            }
        }
    }

    async companyChanged() {
        this.selectedCompanyId = this.selectedCompany.id;
        this.tagsList = await this._tags.getTags(true, this.selectedCompany.id);
        this.tagsList = this.tagsList.filter((tag) => {
            return tag.id > 0;
        });
        this.tmpTags = this.clone(this.tagsList);
        this.editRow = null;
        this.modified = false;
    }

    add() {
        if (this.editRow || this.editRow === 0) {
            return;
        }
        const newTag = {
            id: null,
            name: '',
            companyId: this.selectedCompany.id,
            Errors: []
        };

        this.tagsList.unshift(newTag);
        this.tmpTags = this.clone(this.tagsList);
        this.editRow = 0;
    }

    edit(rowIndex: number) {
        if (this.editRow || this.editRow === 0) {
            return;
        }
        this.editRow = rowIndex;
    }

    async save(tag: TagInstance, index: number) {
        let res = null;

        if (tag.id) {
            res = await this._tags.update(tag);
        } else {
            res = await this._tags.create(tag);
            tag.id = res.id;
        }
        this.tagsList[index] = tag;
        this.tagsList = [...this.tagsList];
        this.tmpTags = this.clone(this.tagsList);
        this.modified = false;
        this.editRow = null;
        this._ui.openSnackBar(this._translate.instant('tags.saved'));
    }

    setModified() {
        this.modified = true;
    }

    async delete(tag: TagInstance, index: number) {
        if (!tag.id) {
            this.tmpTags.shift();
            this.tagsList.shift();
            this.tmpTags = [...this.tmpTags];
            this.tagsList = [...this.tagsList];
            this.editRow = null;
            this.modified = false;
            return;
        }

        const ref = this._dialog.open(DialogComponent, {
            width: '600px',
            data: {
                title: this._translate.instant('tags.dialog.title'),
                message: this._translate.instant('tags.dialog.text', { prod: tag.name }),
                disabled: 'no'
            }
        });

        ref.afterClosed().subscribe(async (result) => {
            if (result) {
                await this._tags.deleteTag(tag.id);

                this.tagsList.splice(index, 1);
                this.tmpTags.splice(index, 1);

                this.tagsList = [...this.tagsList];
                this.tmpTags = [...this.tmpTags];

                this._ui.openSnackBar(this._translate.instant('tags.deleted'));
                this.editRow = null;
                this.modified = false;
            }
        });
    }

    back(rowIndex: number) {
        if (this.tagsList[rowIndex].id) {
            this.tmpTags[rowIndex].name = this.tagsList[rowIndex].name;
        } else {
            this.tmpTags.shift();
            this.tagsList.shift();
        }

        this.tmpTags = [...this.tmpTags];
        this.tagsList = [...this.tagsList];
        this.editRow = null;
        this.modified = false;
    }

    clone(array: any[]) {
        return array.map(x => Object.assign({}, x));
    }
}
