import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { WorkInstance } from '../../../models/work.model';

@Component({
    selector: 'app-works-list-cell',
    templateUrl: './works-list-cell.component.html',
    styleUrls: ['./works-list-cell.component.scss'],
})
export class WorksListCellComponent implements OnInit {
    @Input() tplKey: any;
    @Input() data: any;

    @Output() editWorkClicked = new EventEmitter<WorkInstance>();
    @Output() deleteWorkClicked = new EventEmitter<WorkInstance>();

    showEllipsisTooltip: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    
    pencilClick(work: WorkInstance) {
        this.editWorkClicked.emit(work);
    }

    trashClick(work: WorkInstance) {
        this.deleteWorkClicked.emit(work);
    }
}