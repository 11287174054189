import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';

import { DeviceInstance } from '../models/device.model';
import { UnipiInstance } from '../models/unipi.model';

@Injectable()
export class DeviceService {
    devices: DeviceInstance[];

    constructor(private _http: HttpService, private _config: ConfigService) { }

    async createDevice(data: Object): Promise<DeviceInstance> {
        const url = `${this._config.API.url}/device`;
        const response = await this._http.post<DeviceInstance>(url, data);

        const device = response.body;
        return this.formatDevice(device);
    }

    async createThirdPartyDevice(data: Object): Promise<DeviceInstance> {
        const url = `${this._config.API.url}/device/thirdparty`;
        const response = await this._http.post<DeviceInstance>(url, data);

        const device = response.body;
        return this.formatDevice(device);
    }

    /**
     * Update the device
     *
     * @param {Object} data
     * @returns {Promise<DeviceInstance>}
     * @memberof DeviceService
     */
    public async updateDevice(deviceId: number, data: DeviceInstance): Promise<any> {
        const url = `${this._config.API.url}/device/${deviceId}`;
        const response = await this._http.put(url, data);
    }
    public async updatePriorityError(errorId: number, deviceId: number): Promise<any> {
        const url = `${this._config.API.url}/device/setPriorityError/${errorId}`;
        const response = await this._http.put(url, {deviceId: deviceId});
        return response;
    }

    async getDevices() {
        const url = `${this._config.API.url}/device?populateCompany=true`;
        const response = await this._http.get<DeviceInstance[]>(url);

        const devices = response.body;
        this.devices = devices.map(device => this.formatDevice(device));
        return this.devices;
    }

    async getDevicesByCompany(companyId: number) {
        const url = `${this._config.API.url}/device?populateCompany=true&companyId=${companyId}`;
        const response = await this._http.get<DeviceInstance[]>(url);

        const devices = response.body;
        this.devices = devices.map(device => this.formatDevice(device));
        return this.devices;
    }

    async getDevicesByPlant(companyId: number, plantId: number) {
        const url = `${this._config.API.url}/device?companyId=${companyId}&plantId=${plantId}`;
        const response = await this._http.get<DeviceInstance[]>(url);

        return response.body;
    }

    async getUnipis(initialized?: boolean): Promise<UnipiInstance[]> {
        const url = `${this._config.API.url}/unipi${ initialized !== undefined ? `?init=${initialized}` : '' }`;
        const response = await this._http.get<UnipiInstance[]>(url);

        return response.body;
    }

    /**
     * Format device fields, i.e. threshold in seconds rather than ms
     *
     * @param {DeviceInstance} device
     * @returns {DeviceInstance}
     * @memberof DeviceService
     */
    formatDevice(device: DeviceInstance): DeviceInstance {
        if (device.stopProductsThreshold) {
            device.stopProductsThresholdSec = Math.trunc(device.stopProductsThreshold / 1000);
        }
        if (typeof device.enabled == 'undefined' || device.enabled == null) {
            device.enabled = true;
        }
        return device;
    }
}
