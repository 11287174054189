import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DeviceInstance } from '../../../models/device.model';
import { ErrorInstance, ErrorType } from '../../../models/error.model';
import { ErrorCategoryInstance } from '../../../models/error-category.model';

import { DeviceService } from '../../../services/device.service';
import { ErrorService } from '../../../services/error.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { UiService } from '../../../services/ui.service';

import { DialogComponent } from '../../ui/dialog/dialog.component';
import { DevicesDialogComponent } from '../../ui/devices-dialog/devices-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { TagInstance } from '../../../models/tag.model';
import { TagsService } from '../../../services/tag.service';
import { AuthService } from '../../../services/auth.service';
import { UserRole } from '../../../models/user.model';
import { DelaysService } from '../../../services/delays.service';
import { DelayInstance } from '../../../models/delay.model';
import { UserView, ViewService } from '../../../services/view.service';
import { ErrorDialogComponent, SaveChoice } from '../../ui/error-dialog/error-dialog.component';


@Component({
    selector: 'app-errors',
    templateUrl: './errors.component.html',
    styleUrls: ['./errors.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ErrorsComponent implements OnInit {
    isDelaysManager = false;

    errorCategories: ErrorCategoryInstance[];
    errorTags: TagInstance[];
    errors: ErrorInstance[];
    planned: ErrorInstance[];
    unplanned: ErrorInstance[];
    delays: DelayInstance[];
    tmpPlanned: ErrorInstance[];
    tmpUnplanned: ErrorInstance[];
    tmpDelays: ErrorInstance[];
    devices: DeviceInstance[];
    selectedDevice: DeviceInstance;
    showPlanned = true;
    showDelays = false;
    isAdmin = false;
    modifiedPlanned: boolean;
    modifiedUnPlanned: boolean;
    modifiedDelay: boolean;
    plannedEditRow: number;
    unPlannedEditRow: number;
    delaysEditRow: number;
    columns = [];
    delayColumns = [];
    unplannedColumns = [];

    selectedCompanyId: number;

    plannedSearch: string;
    unplannedSearch: string;

    plannedCategoryFilter: ErrorCategoryInstance;
    unplannedCategoryFilter: ErrorCategoryInstance;

    ErrorType = ErrorType;

    @ViewChild('priority', { static: true }) priorityTemplate: TemplateRef<any>;
    @ViewChild('code', { static: true }) codeTemplate: TemplateRef<any>;
    @ViewChild('text', { static: true }) textTemplate: TemplateRef<any>;
    @ViewChild('description', { static: true }) descriptionTemplate: TemplateRef<any>;
    @ViewChild('category', { static: true }) categoryTemplate: TemplateRef<any>;
    @ViewChild('target', { static: true }) targetTemplate: TemplateRef<any>;
    @ViewChild('tag', { static: true }) tagTemplate: TemplateRef<any>;
    @ViewChild('enabled', { static: true }) enabledTemplate: TemplateRef<any>;
    @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
    @ViewChild('actionsDelay', { static: true }) actionsDelayTemplate: TemplateRef<any>;
    @ViewChild('devicesTemplate', { static: true }) devicesTemplate: TemplateRef<any>;
    @ViewChild('addItem', { static: true }) addItemTemplate: TemplateRef<any>;

    constructor(
        private _device: DeviceService,
        private _error: ErrorService,
        private _ui: UiService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _dialog: MatDialog,
        private _translate: TranslateService,
        private _errorTag: TagsService,
        private _auth: AuthService,
        public _viewService: ViewService,
        private _delayService: DelaysService
    ) { }

    async ngOnInit() {
        if (!!this._auth && !!this._auth.user) {
        // check if the current user is an admin
        this.isAdmin = this._auth.user.role === UserRole.admin;

        this._viewService.userCanView(this._auth.user, UserView.delays)
        .then( userCanViewDelays => {
            this.isDelaysManager = userCanViewDelays;
        });
    }


        this._translate
            .stream([
                'stopReasons.title',
                'stopReasons.columns.priority',
                'stopReasons.columns.code',
                'stopReasons.columns.text',
                'stopReasons.columns.category',
                'stopReasons.columns.target',
                'stopReasons.columns.tag',
                'stopReasons.columns.enabled',
                'stopReasons.columns.devices',
            ])
            .subscribe((translations) => {
                this._navbar.setTitle(translations['stopReasons.title']);
                setTimeout(() => this._sidebar.selected = 'stop_reasons');

                this.columns = [{
                    resizeable: false,
                    canAutoResize: true,
                    width: 250,
                    name: translations['stopReasons.columns.code'],
                    comparator: this.textComparator.bind(this),
                    cellTemplate: this.codeTemplate,
                    prop: 'code'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 250,
                    name: translations['stopReasons.columns.text'],
                    comparator: this.textComparator.bind(this),
                    cellTemplate: this.textTemplate,
                    prop: 'text'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 250,
                    name: translations['stopReasons.columns.category'],
                    cellTemplate: this.categoryTemplate,
                    comparator: this.categoryComparator.bind(this),
                    prop: 'categoryId'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 200,
                    name: translations['stopReasons.columns.tag'],
                    comparator: this.groupComparator.bind(this),
                    cellTemplate: this.tagTemplate,
                    // prop: 'ErrorsTags[0]'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 250,
                    name: translations['stopReasons.columns.target'],
                    cellTemplate: this.targetTemplate,
                    comparator: this.targetComparator.bind(this),
                    prop: 'target'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 150,
                    name: translations['stopReasons.columns.devices'],
                    prop: 'Devices.length',
                    cellTemplate: this.devicesTemplate,
                    comparator: this.devicesComparator.bind(this)
                }, {
                    resizeable: false,
                    canAutoResize: false,
                    width: 100,
                    name: translations['stopReasons.columns.enabled'],
                    cellTemplate: this.enabledTemplate,
                    prop: 'enabled'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    name: '',
                    headerTemplate: this.addItemTemplate,
                    cellTemplate: this.actionsTemplate
                }];

                this.unplannedColumns = [{
                    resizeable: false,
                    canAutoResize: true,
                    width: 250,
                    name: translations['stopReasons.columns.code'],
                    comparator: this.textComparator.bind(this),
                    cellTemplate: this.codeTemplate,
                    prop: 'code'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 250,
                    name: translations['stopReasons.columns.text'],
                    comparator: this.textComparator.bind(this),
                    cellTemplate: this.textTemplate,
                    prop: 'text'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 250,
                    name: translations['stopReasons.columns.category'],
                    cellTemplate: this.categoryTemplate,
                    comparator: this.categoryComparator.bind(this),
                    prop: 'categoryId'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 200,
                    name: translations['stopReasons.columns.tag'],
                    comparator: this.groupComparator.bind(this),
                    cellTemplate: this.tagTemplate,
                    // prop: 'ErrorsTags[0]'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 150,
                    name: translations['stopReasons.columns.devices'],
                    prop: 'Devices.length',
                    cellTemplate: this.devicesTemplate,
                    comparator: this.devicesComparator.bind(this)
                }, {
                    resizeable: false,
                    canAutoResize: false,
                    width: 100,
                    name: translations['stopReasons.columns.enabled'],
                    cellTemplate: this.enabledTemplate,
                    prop: 'enabled'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    name: '',
                    headerTemplate: this.addItemTemplate,
                    cellTemplate: this.actionsTemplate
                }];

                if (this.isAdmin) {
                    this.columns =
                        [{
                            resizeable: false,
                            canAutoResize: false,
                            width: 100,
                            name: translations['stopReasons.columns.priority'],
                            cellTemplate: this.priorityTemplate,
                            prop: 'id',
                            sortable: false
                        }].concat(this.columns)

                        this.unplannedColumns =
                        [{
                            resizeable: false,
                            canAutoResize: false,
                            width: 100,
                            name: translations['stopReasons.columns.priority'],
                            cellTemplate: this.priorityTemplate,
                            prop: 'id',
                            sortable: false
                        }].concat(this.unplannedColumns)
                }

                this.delayColumns = [{
                    //     resizeable: false,
                    //     canAutoResize: false,
                    //     width: 100,
                    //     name: translations['stopReasons.columns.priority'],
                    //     cellTemplate: this.priorityTemplate,
                    //     prop: 'id',
                    //     sortable: false
                    // }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 250,
                    name: translations['stopReasons.columns.text'],
                    comparator: this.textComparator.bind(this),
                    cellTemplate: this.descriptionTemplate,
                    prop: 'description'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 150,
                    name: translations['stopReasons.columns.devices'],
                    prop: 'Devices.length',
                    cellTemplate: this.devicesTemplate,
                    comparator: this.devicesComparator.bind(this)
                }, {
                    resizeable: false,
                    canAutoResize: false,
                    width: 100,
                    name: translations['stopReasons.columns.enabled'],
                    cellTemplate: this.enabledTemplate,
                    prop: 'enabled'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    name: '',
                    headerTemplate: this.addItemTemplate,
                    cellTemplate: this.actionsDelayTemplate
                }];
            });

        this.devices = await this._device.getDevices();
        if (this.devices.length > 0) {
            if (localStorage.getItem('deviceId')) {
                const device = this.devices.find((item) => item.id === Number(localStorage.getItem('deviceId')));
                if (device) this.selectedDevice = device;
            } else this.selectedDevice = this.devices[0];
            this.deviceChanged();
        }

        if (this.isAdmin) {
            this.selectedCompanyId = this.selectedDevice.Company.id;
            this.errorCategories = await this._error.getErrorCategories(this.selectedCompanyId);
        } else {
            this.selectedCompanyId = Number(localStorage.getItem('companyId'));
            this.errorCategories = await this._error.getErrorCategories(this.selectedCompanyId);
        }


    }

    async deviceChanged() {
        localStorage.setItem('deviceId', this.selectedDevice.id.toString());
        if (this.isAdmin) {
            this.errorTags = await this._errorTag.getTags(true, this.selectedDevice.Company.id);
        } else {
            this.errorTags = await this._errorTag.getTags();
        }
        this.errorTags = this.errorTags.filter((event) => {
            return event.id > 0;
        });
        this.errorTags.unshift({
            id: 0,
            name: '-- none --'
        });
        this.errors = await this._error.getErrors(this.selectedDevice.id);
        this.planned = [];
        this.unplanned = [];
        this.delays = [];
        if (this.isDelaysManager) {
            this.delays = await this._delayService.getDelays(this.selectedDevice.id);
        }

        this.errors.forEach((e) => {
            if (e.ErrorsTags.length === 0) {
                e.ErrorsTags.push({
                    id: 0
                });
            }
            e.tagId = e.ErrorsTags[0].id;
            if (e.Category.type === ErrorType.planned) {
                this.planned.push(e);
            } else {
                this.unplanned.push(e);
            }
        });
        this.tmpPlanned = this.cloneArray(this.planned);
        this.tmpUnplanned = this.cloneArray(this.unplanned);
        this.tmpDelays = this.cloneArray(this.delays);
        this.plannedEditRow = null;
        this.unPlannedEditRow = null;
        this.delaysEditRow = null;
        this.modifiedPlanned = false;
        this.modifiedUnPlanned = false;
        this.modifiedDelay = false;

    }

    addError() {
        if (this.showPlanned) {
            if (this.plannedEditRow || this.plannedEditRow === 0) {
                return;
            }
        } else {
            if (this.unPlannedEditRow || this.unPlannedEditRow === 0) {
                return;
            }
        }
        const errorObj = {
            text: '',
            categoryId: -1,
            type: null,
            companyId: this.selectedDevice.Company.id,
            enabled: true,
            Devices: [this.selectedDevice],
            tagId: 0,
            ErrorsTags: [{ id: 0 }]
        };

        if (this.showPlanned) {
            errorObj.type = ErrorType.planned;
            this.planned.unshift(errorObj);
            this.planned = [...this.planned];
            this.tmpPlanned = this.cloneArray(this.planned);
            this.plannedEditRow = 0;
        } else {
            errorObj.type = ErrorType.unplanned;
            this.unplanned.unshift(errorObj);
            this.unplanned = [...this.unplanned];
            this.tmpUnplanned = this.cloneArray(this.unplanned);
            this.unPlannedEditRow = 0;
        }
    }

    async updatePriorityError(errorId) {
        this.selectedDevice.priorityErrorId = errorId;
        await this._device.updatePriorityError(errorId, this.selectedDevice.id);
    }

    edit(rowIndex: number) {
        if (this.showPlanned) {
            if (this.plannedEditRow || this.plannedEditRow === 0) {
                return;
            }
            this.plannedEditRow = rowIndex;
        } else if (this.showDelays) {
            if (this.delaysEditRow || this.delaysEditRow === 0) {
                return;
            }
            this.delaysEditRow = rowIndex;
        } else {
            if (this.unPlannedEditRow || this.unPlannedEditRow === 0) {
                return;
            }
            this.unPlannedEditRow = rowIndex;
        }
    }

    async saveError(err: ErrorInstance, index: number) {
        let res = null;
        let dialogResult = null;
        if (err.Devices.length > 1) {
            const ref = this._dialog.open(ErrorDialogComponent, {
                width: '700px',
                data: {
                    title: this._translate.instant('dialogs.errors.applychangestoalldevices_info_header'),
                    message: this._translate.instant('dialogs.errors.applychangestoalldevices_info_text', {
                        deviceName: this.selectedDevice.label
                    }),
                    disabled: 'no'
                },
                disableClose: true
            });

            dialogResult = await ref.afterClosed().toPromise();
            if (!dialogResult || dialogResult === '') {
                return;
            }
        }
        if (err.id) {
            res = await this._error.updateError(err, dialogResult, this.selectedDevice);
        } else {
            res = await this._error.saveNewError(err, dialogResult, this.selectedDevice.id);
            err.id = res.id;
        }
        if (err.tagId !== err.ErrorsTags[0].id) {
            if (!err.ErrorsTags[0].id) {
                await this._error.removeErrorTag(err.id, err.tagId);
            } else if (!err.tagId) {
                await this._error.addErrorTag(err.id, err.ErrorsTags[0].id);
            } else {
                await this._error.updateErrorTag(err.id, err.tagId, err.ErrorsTags[0].id);
            }
        }
        if (this.showPlanned) {
            this.planned[index] = err;
            this.plannedEditRow = null;
            this.modifiedPlanned = false;
        } else {
            this.unplanned[index] = err;
            this.unPlannedEditRow = null;
            this.modifiedUnPlanned = false;
        }
        await this.deviceChanged();
        this._ui.openSnackBar(this._translate.instant('stopReasons.saved'));
    }

    setModified(index) {
        if (this.showPlanned) {
            this.modifiedPlanned = true;
        } else if (this.showDelays) {
            this.modifiedDelay = true;
        } else {
            this.modifiedUnPlanned = true;
        }
    }

    tabChanged(e) {
        this.showPlanned = false;
        this.showDelays = false;
        switch (e.index) {
            case 0:
                this.showPlanned = true;
                break;
            case 1:
                this.showPlanned = false;
                break;
            case 2:
                this.showDelays = true;
                break;
            default:
                break;
        }
    }

    textComparator(a, b) {
        return a > b ? -1 : 1;
    }

    categoryComparator(a, b) {
        const cat1 = this.errorCategories.find(e => e.id === a);
        const cat2 = this.errorCategories.find(e => e.id === b);

        return (cat1.name.toUpperCase() > cat2.name.toUpperCase()) ? -1 : 1;
    }

    targetComparator(a, b) {
        return (a.toUpperCase() > b.toUpperCase()) ? -1 : 1;
    }

    groupComparator(a, b, rowA, rowB) {
        let tag1 = this.errorTags.find(e => e.id === rowA.ErrorsTags[0].id);
        let tag2 = this.errorTags.find(e => e.id === rowB.ErrorsTags[0].id);
        if (!tag1 || tag1.id === 0) {
            tag1 = { id: 0, name: 'zzz' };
        }
        if (!tag2 || tag2.id === 0) {
            tag2 = { id: 0, name: 'zzz' };
        }
        return (tag1.name.toUpperCase() > tag2.name.toUpperCase()) ? -1 : 1;
    }

    devicesComparator(a, b) {
        return a > b ? -1 : 1;
    }

    filterPlannedReset() {
        this.plannedSearch = null;
        this.plannedCategoryFilter = null;
        this.tmpPlanned = [...this.planned];
    }

    filterPlanned() {
        const tmp = this.planned.filter((d) => {
            if (this.plannedCategoryFilter) {
                if (d.categoryId !== this.plannedCategoryFilter.id) return false;
            }

            if (this.plannedSearch && this.plannedSearch.trim() !== '') {
                if (d.text.toLowerCase().indexOf(this.plannedSearch.toLowerCase()) === -1) {
                    return false;
                }
            }

            return true;
        });

        this.tmpPlanned = [...tmp];
    }

    filterUnplannedReset() {
        this.unplannedSearch = null;
        this.unplannedCategoryFilter = null;
        this.tmpUnplanned = [...this.unplanned];
    }

    filterUnplanned() {
        const tmp = this.unplanned.filter((d) => {
            if (this.unplannedCategoryFilter) {
                if (d.categoryId !== this.unplannedCategoryFilter.id) return false;
            }

            if (this.unplannedSearch && this.unplannedSearch.trim() !== '') {
                if (d.text.toLowerCase().indexOf(this.unplannedSearch.toLowerCase()) === -1) {
                    return false;
                }
            }

            return true;
        });

        this.tmpUnplanned = [...tmp];
    }

    async manageDevices(err, index: number) {
        let allDevices = this.devices ? this.devices.filter(dev => dev.Company && dev.Company.id === this.selectedDevice.Company.id) : [];
        allDevices = allDevices.filter(dev => dev.plantId === this.selectedDevice.plantId);

        const ref = this._dialog.open(DevicesDialogComponent, {
            width: '600px',
            data: {
                allDevices,
                selectedDevices: err.Devices,
                companyId: err.companyId
            }
        });

        ref.afterClosed().subscribe(async (result) => {
            if (result) {
                err.Devices = result;
                this.setModified(index);
            }
        });
    }

    isEditRow(rowIndex: number) {
        if (this.showPlanned) {
            return this.plannedEditRow === rowIndex;
        } else if (this.showDelays) {
            return this.delaysEditRow === rowIndex;
        } else {
            return this.unPlannedEditRow === rowIndex;
        }
    }

    isEditMode(rowIndex: number) {
        if (this.showPlanned) {
            return (this.plannedEditRow || this.plannedEditRow === 0) && !this.isEditRow(rowIndex);
        } else if (this.showDelays) {
            return (this.delaysEditRow || this.delaysEditRow === 0) && !this.isEditRow(rowIndex);
        } else {
            return (this.unPlannedEditRow || this.unPlannedEditRow === 0) && !this.isEditRow(rowIndex);
        }
    }

    isModified() {
        if (this.showPlanned) {
            return this.modifiedPlanned;
        } else if (this.showDelays) {
            return this.modifiedDelay;
        } else {
            return this.modifiedUnPlanned;
        }
    }

    back(rowIndex: number) {
        if (this.showPlanned) {
            if (this.planned[rowIndex].id) {
                this.tmpPlanned[rowIndex] = this.cloneObject(this.planned[rowIndex]);
            } else {
                this.planned.shift();
                this.tmpPlanned.shift();
            }
            this.planned = [...this.planned];
            this.tmpPlanned = [...this.tmpPlanned];

            this.plannedEditRow = null;
            this.modifiedPlanned = false;
        } else {
            if (this.unplanned[rowIndex].id) {
                this.tmpUnplanned[rowIndex] = this.cloneObject(this.unplanned[rowIndex]);
            } else {
                this.unplanned.shift();
                this.tmpUnplanned.shift();
            }
            this.unplanned = [...this.unplanned];
            this.tmpUnplanned = [...this.tmpUnplanned];

            this.unPlannedEditRow = null;
            this.modifiedUnPlanned = false;
        }
    }

    cloneArray(array: any[]) {
        return array.map(x => Object.assign({}, x));
    }
    cloneObject(object: any) {
        return Object.assign({}, object);
    }

    async deleteError(err: ErrorInstance, index: number) {
        if (!err.id) {
            if (this.showPlanned) {
                this.planned.splice(index, 1);
                this.planned = [...this.planned];
                this.tmpPlanned = this.cloneArray(this.planned);
                this.plannedEditRow = null;
                this.modifiedPlanned = false;
            } else {
                this.unplanned.splice(index, 1);
                this.unplanned = [...this.unplanned];
                this.tmpUnplanned = this.cloneArray(this.unplanned);
                this.unPlannedEditRow = null;
                this.modifiedUnPlanned = false;
            }
            return;
        }

        const ref = this._dialog.open(DialogComponent, {
            width: '600px',
            data: {
                title: this._translate.instant('stopReasons.dialog.title'),
                message: this._translate.instant('stopReasons.dialog.text', { err: err.text, count: err.count || 0 })
            }
        });

        ref.afterClosed().subscribe(async (result) => {
            if (result === 'delete') {
                await this._error.deleteError(err.id);

                // Remove from table
                if (this.showPlanned) {
                    this.planned.splice(index, 1);
                    this.planned = [...this.planned];
                    this.tmpPlanned = this.cloneArray(this.planned);
                    this.plannedEditRow = null;
                    this.modifiedPlanned = false;
                } else {
                    this.unplanned.splice(index, 1);
                    this.unplanned = [...this.unplanned];
                    this.tmpUnplanned = this.cloneArray(this.unplanned);
                    this.unPlannedEditRow = null;
                    this.modifiedUnPlanned = false;
                }

                this._ui.openSnackBar(this._translate.instant('stopReasons.deleted'));
            } else if (result === 'disable') {
                err.enabled = false;
                await this._error.updateError(err);

                // Set as disabled
                if (this.showPlanned) {
                    this.planned[index] = this.cloneObject(err);
                    this.plannedEditRow = null;
                    this.modifiedPlanned = false;
                } else {
                    this.unplanned[index] = this.cloneObject(err);
                    this.unPlannedEditRow = null;
                    this.modifiedUnPlanned = false;
                }

                this._ui.openSnackBar(this._translate.instant('stopReasons.disabled'));
            }

        });
    }

    // Delay functions

    backDelay(rowIndex: number) {
        if (this.showDelays) {
            if (this.delays[rowIndex].id) {
                this.tmpDelays[rowIndex] = this.cloneObject(this.delays[rowIndex]);
            } else {
                this.delays.shift();
                this.tmpDelays.shift();
            }
            this.delays = [...this.delays];
            this.tmpDelays = [...this.tmpDelays];

            this.delaysEditRow = null;
            this.modifiedDelay = false;
        }
    }

    addDelay() {
        if (this.delaysEditRow || this.delaysEditRow === 0) {
            return;
        }
        const delayObj: DelayInstance = {
            description: '',
            companyId: this.selectedCompanyId,
            plantId: this.selectedDevice.plantId,
            enabled: true,
            Devices: [this.selectedDevice],
        };

        this.delays.unshift(delayObj);
        this.delays = [...this.delays];
        this.tmpDelays = this.cloneArray(this.delays);
        this.delaysEditRow = 0;
    }

    async saveDelay(delay: DelayInstance, index: number) {
        try {
            let res = null;

            if (delay.id) {
                res = await this._delayService.updateDelay(delay);
            } else {
                res = await this._delayService.saveNewDelay(delay);
                delay.id = res.id;
            }
            if (this.showDelays) {
                this.delays[index] = delay;
                this.delaysEditRow = null;
                this.modifiedDelay = false;
            }
            this._ui.openSnackBar(this._translate.instant('stopReasons.saveDelay'));
        } catch (error) {
            console.log(error);
            this._ui.openSnackBar(this._translate.instant('stopReasons.saveDelayError'));
        }
    }

    async deleteDelay(delay: DelayInstance, index: number) {
        if (!delay.id) {
            if (this.showDelays) {
                this.delays.splice(index, 1);
                this.delays = [...this.delays];
                this.tmpDelays = this.cloneArray(this.delays);
                this.delaysEditRow = null;
                this.modifiedDelay = false;
            }
            return;
        }

        const ref = this._dialog.open(DialogComponent, {
            width: '600px',
            data: {
                title: this._translate.instant('stopReasons.dialog.delays'),
                message: this._translate.instant('stopReasons.dialog.description', { err: delay.description || 0 })
            }
        });

        ref.afterClosed().subscribe(async (result) => {
            if (result === 'delete') {
                try {
                    const res = await this._delayService.deleteDelay(delay.id);

                    if (res.status === 200) {
                        // Remove from table
                        if (this.showDelays) {
                            this.delays.splice(index, 1);
                            this.delays = [...this.delays];
                            this.tmpDelays = this.cloneArray(this.delays);
                            this.delaysEditRow = null;
                            this.modifiedDelay = false;
                        }
                        this._ui.openSnackBar(this._translate.instant('stopReasons.deleteDelay'));
                    }
                } catch (error) {
                    console.log(error);
                    this._ui.openSnackBar(this._translate.instant('stopReasons.deleteDelayError'));
                }
            } else if (result === 'disable') {
                try {
                    delay.enabled = false;
                    await this._delayService.updateDelay(delay);
                    // Set as disabled
                    if (this.showDelays) {
                        this.delays[index] = this.cloneObject(delay);
                        this.delaysEditRow = null;
                        this.modifiedDelay = false;
                    }
                    this._ui.openSnackBar(this._translate.instant('stopReasons.disableDelay'));
                } catch (error) {
                    console.log(error);
                    this._ui.openSnackBar(this._translate.instant('stopReasons.disableDelayError'));
                }
            }

        });
    }
}
