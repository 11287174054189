import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DeviceService } from '../../../services/device.service';
import { UiService } from '../../../services/ui.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';

import { DeviceInstance } from '../../../models/device.model';
import { QrDialogComponent } from '../../ui/qr-dialog/qr-dialog.component';

import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/auth.service';
import { UserRole } from '../../../models/user.model';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DevicesComponent implements OnInit {
  devices: DeviceInstance[];
  tmpDevices: DeviceInstance[];
  columns = [];
  isAdmin = false;
  editRow: number;
  modified: boolean;
  codeSearch: string;

  @ViewChild('label', { static: true }) labelTemplate: TemplateRef<any>;
  @ViewChild('stopThreshold', { static: true }) stopThresholdTemplate: TemplateRef<any>;
  @ViewChild('startThreshold', { static: true }) startThresholdTemplate: TemplateRef<any>;
  @ViewChild('multiplier', { static: true }) multiplierTemplate: TemplateRef<any>;
  @ViewChild('scrapsMultiplier', { static: true }) scrapsMultiplierTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('enabled', { static: true }) enabledTemplate: TemplateRef<any>;

  constructor(
    private _device: DeviceService,
    private _ui: UiService,
    private _navbar: NavbarService,
    private _sidebar: SidebarService,
    private _dialog: MatDialog,
    private _translate: TranslateService,
    public auth: AuthService
  ) { }

  async ngOnInit() {
    this._navbar.setTitle('Devices');
    setTimeout(() => this._sidebar.setSelected('devices'));

    if (!!this.auth && !!this.auth.user) {
      this.isAdmin = this.auth.user.role === UserRole.admin;
    } else {
      console.error('No auth service/user');
    }

    this._translate.stream([
      'devices.columns.id',
      'devices.columns.name',
      'devices.columns.serial',
      'devices.columns.company',
      'devices.columns.stopThreshold',
      'devices.columns.startThreshold',
      'devices.columns.multiplier',
      'devices.columns.scraps-multiplier',
      'devices.columns.enabled'
    ]).subscribe((translations) => {
      this.columns = [{
        resizable: true,
        name: translations['devices.columns.name'],
        prop: 'label',
        cellTemplate: this.labelTemplate,
        // comparator: this.stringComparator.bind(this)
      }, {
        resizable: true,
        name: translations['devices.columns.stopThreshold'],
        prop: 'stopProductsThreshold',
        cellTemplate: this.stopThresholdTemplate,
      }, {
        resizable: true,
        name: translations['devices.columns.startThreshold'],
        prop: 'resumeProductionProductsThreshold',
        cellTemplate: this.startThresholdTemplate,
      }, {
        resizable: true,
        name: translations['devices.columns.multiplier'],
        prop: 'multiplier',
        cellTemplate: this.multiplierTemplate,
      },
      {
        resizable: true,
        name: translations['devices.columns.scraps-multiplier'],
        prop: 'scrapsMultiplier',
        cellTemplate: this.scrapsMultiplierTemplate,
      },
      {
        resizeable: false,
        canAutoResize: true,
        name: '',
        cellTemplate: this.actionsTemplate
      }];

      if (this.isAdmin) {
        this.columns.splice(0, 0, {
          resizable: false,
          canAutoResize: true,
          name: translations['devices.columns.id'],
          prop: 'id',
          flexGrow: 1
          // cellTemplate: this.numberTemplate,
          // comparator: this.numberComparator.bind(this)
        });

        this.columns.splice(2, 0, {
          resizable: true,
          name: translations['devices.columns.serial'],
          prop: 'serial',
          // cellTemplate: this.stringTemplate,
          // comparator: this.stringComparator.bind(this)
        }, {
          resizable: true,
          name: translations['devices.columns.company'],
          prop: 'Company.name',
          // cellTemplate: this.stringTemplate,
          // comparator: this.stringComparator.bind(this)
        });

        this.columns.splice(this.columns.length - 1, 0, {
          resizable: true,
          name: translations['devices.columns.enabled'],
          prop: 'enabled',
          cellTemplate: this.enabledTemplate,
        });

      }
    });

    this.devices = await this._device.getDevices();
    this.tmpDevices = this.cloneArray(this.devices);
  }

  // Device table actions
  async showQr(device: DeviceInstance, index: number) {
    if (this.editRow || this.editRow === 0) {
      return;
    }
    device.serial = device.serial || 'LOL';

    const ref = this._dialog.open(QrDialogComponent, {
      width: '400px',
      data: device
    });

    ref.afterClosed().subscribe(async (result) => {
      // Print QR code
      if (typeof result === 'object' && result.name === 'print') {
        const popup = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popup.document.open();
        popup.document.write(`
        <html>
        <style>
        * {
          font-family: "Helvetica Neue", Helvetica, Verdana, Arial, sans-serif;
          font-size: 10pt;
        }
        </style>
        <body onload="window.print(); window.close();" style="text-align: center;">
        <p>${device.label}</p>
        <img src="${result.src}" style="width: 25mm; height: 25mm;"/>
        <p>S/N: ${device.serial}</p>
        </body>
        </html>
        `);
        popup.document.close();
      }
    });
  }

  edit(rowIndex: number) {
    if (this.editRow || this.editRow === 0) {
      return;
    }
    this.editRow = rowIndex;
  }

  back(rowIndex: number) {
    this.tmpDevices = this.cloneArray(this.devices);
    this.editRow = null;
    this.modified = false;
  }

  setModified(index) {
    this.modified = true;
  }

  async save(device: DeviceInstance, index: number) {
    // if (device.resumeProductionProductsThreshold != null && device.resumeProductionProductsThreshold < 2) {
    //     this._ui.openSnackBar(this._translate.instant('products.threshold_too_low'));
    //     return;
    // }
    try {
      await this._device.updateDevice(device.id, {
        label: device.label,
        stopProductsThreshold: device.stopProductsThresholdSec * 1000,
        resumeProductionProductsThreshold: device.resumeProductionProductsThreshold,
        multiplier: device.multiplier,
        scrapsMultiplier: device.scrapsMultiplier,
        enabled: device.enabled
      });
      this.devices[index] = this.cloneObject(device);
      this.modified = false;
      this.editRow = null;
      this._ui.openSnackBar(this._translate.instant('devices.save'));
    } catch (err) {
      console.error(err);
      this._ui.openSnackBar(this._translate.instant('devices.error'));
    }
  }
  cloneArray(array: any[]) {
    return array.map(x => Object.assign({}, x));
  }
  cloneObject(object: any) {
    return Object.assign({}, object);
  }
  getStopTreshold(row: DeviceInstance): string {
    if (row.stopProductsThresholdSec) {
      return '' + row.stopProductsThresholdSec;
    } else {
      return '35';
    }
  }

  getStartTreshold(row: DeviceInstance): string {
    if (row.resumeProductionProductsThreshold) {
      return '' + row.resumeProductionProductsThreshold;
    } else {
      return '30';
    }
  }

  getMultiplier(row: DeviceInstance): string {
    if (row.multiplier) {
      return '' + row.multiplier;
    } else {
      return '1';
    }
  }

  getScrapMultiplier(row: DeviceInstance): string {
    if (row.scrapsMultiplier) {
      return '' + row.scrapsMultiplier;
    } else {
      return '1';
    }
  }

  getEnabledStatus(row: DeviceInstance): boolean {
    if (typeof row.enabled != 'undefined') {
      return row.enabled;
    }

    return true;
  }

  public filter() {
    const filter = this.devices.filter((item) => {
      return item.label.toLowerCase().indexOf(this.codeSearch.toLowerCase()) > -1;
    });

    this.tmpDevices = this.cloneArray(filter);
  }

  public filterAdmin() {
    const filter = this.devices.filter((item) => {
      return item.label.toLowerCase().indexOf(this.codeSearch.toLowerCase()) > -1 || item.id.toString().indexOf(this.codeSearch.toLowerCase()) > -1 || item.Unipi.serial.toString().indexOf(this.codeSearch.toLowerCase()) > -1;
    });

    this.tmpDevices = this.cloneArray(filter);
  }

  public filterReset() {
    this.codeSearch = '';
    this.tmpDevices = [];
    this.tmpDevices = this.cloneArray(this.devices);
  }


}

