import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation, Inject, LOCALE_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DeviceService } from '../../../services/device.service';
import { UiService } from '../../../services/ui.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';

import { UnipiInstance } from '../../../models/unipi.model';

import { NewDeviceDialogComponent } from '../../ui/new-device-dialog/new-device-dialog.component';
import { NewVirtualDeviceDialogComponent } from '../../ui/new-virtual-device-dialog/new-virtual-device-dialog.component';
import { NewThirdPartyDeviceDialogComponent } from '../../ui/new-thirdparty-device-dialog/new-thirdparty-device-dialog.component';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-new-device',
  templateUrl: './new-device.component.html',
  styleUrls: ['./new-device.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewDeviceComponent implements OnInit {
  unipis: UnipiInstance[];

  columns = [];

  @ViewChild('label', { static: true }) labelTemplate: TemplateRef<any>;
  @ViewChild('date', { static: true }) dateTemplate: TemplateRef<any>;
  // @ViewChild('devicesTemplate') devicesTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('newVirtualDeviceDialog', { static: true }) newVirtualDeviceDialogTemplate: TemplateRef<any>;
  @ViewChild('newThirdPartyDeviceDialog', { static: true }) newThirdpartyDeviceDialogTemplate: TemplateRef<any>;
  
  constructor(
    private _device: DeviceService,
    private _ui: UiService,
    private _navbar: NavbarService,
    private _sidebar: SidebarService,
    private _dialog: MatDialog,
    private _translate: TranslateService
  ) { }

  async ngOnInit() {
    this._navbar.setTitle('Devices');
    setTimeout(() => this._sidebar.setSelected('new-device'));

    this._translate.stream([
      'devices.columns.id',
      'devices.columns.serial'
    ]).subscribe((translations) => {

      this.columns = [{
        resizable: false,
        canAutoResize: true,
        name: translations['devices.columns.id'],
        prop: 'id',
        // cellTemplate: this.numberTemplate,
        // comparator: this.numberComparator.bind(this)
      }, {
        resizable: true,
        name: translations['devices.columns.serial'],
        prop: 'serial',
        // cellTemplate: this.stringTemplate,
        // comparator: this.stringComparator.bind(this)
      }, {
        resizable: true,
        name: '',
        prop: 'createdAt',
        cellTemplate: this.dateTemplate,
        // comparator: this.stringComparator.bind(this)
      },
      {
        resizeable: false,
        canAutoResize: true,
        name: '',
        headerTemplate: this.newThirdpartyDeviceDialogTemplate
      },
      {
        resizeable: false,
        canAutoResize: true,
        name: '',
        headerTemplate: this.newVirtualDeviceDialogTemplate,
        cellTemplate: this.actionsTemplate
      }];
    });

    await this.loadDevices();
  }

  async loadDevices() {
    this.unipis = await this._device.getUnipis(false);
    this.unipis.map((value) => {
      value.createdAt = moment(value.createdAt).format('DD/MM/YYYY HH:mm');
    });
  }

  async newDialog(unipi: UnipiInstance, index: number) {
    const ref = this._dialog.open(NewDeviceDialogComponent, {
      width: '480px',
      data: { unipiId: unipi.id }
    });

    ref.afterClosed().subscribe(async (result) => {
      if (result) {
        console.error(result);
        this._ui.openSnackBar('An error has occurred while creating a new device');
      } else {
        this.unipis = this.unipis.filter((item) => item.id !== unipi.id);
      }
    });
  }

  async openVirtualDeviceDialog() {
    const ref = this._dialog.open(NewVirtualDeviceDialogComponent, {
      width: '480px',
      data: {}
    });

    ref.afterClosed().subscribe( (result) => {
      // console.log("After closed result", result)
      if (result) {
        console.error(result);
        this._ui.openSnackBar('An error has occurred while creating a new virtual device');
      } else {
        //TODO: Should redirect to Active devices after creation?
      }
    });
  }
  async openThirdPartyDeviceDialog() {
    const ref = this._dialog.open(NewThirdPartyDeviceDialogComponent, {
      width: '480px',
      data: {}
    });

    ref.afterClosed().subscribe( (result) => {
      // console.log("After closed result", result)
      if (result) {
        console.error(result);
        this._ui.openSnackBar('An error has occurred while creating a new third party device');
      } else {
        //TODO: Should redirect to Active devices after creation?
      }
    });
  }
}
