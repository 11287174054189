import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft, faInfoSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontService, NotificationConfig, NotificationService, NotificationStyle, OverlaySpinnerService, PopupBodyConfig, PopupConfig, PopupResult, PopupService } from '@vapor/angular-ui';
import { ProductsFamilyInstance } from '../../../models/productsFamily.model';
import { ProductFamilyService } from '../../../services/productFamily.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifyLevel } from '@vapor/angular-ui/popup/popup.models';
import { JsonTranslatorPipe } from '../../../pipes/json-translator.pipe';

@Component({
    selector: 'app-new-family',
    templateUrl: './new-family.component.html',
    styleUrls: ['./new-family.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        NotificationService,
        JsonTranslatorPipe,
    ],
})
export class NewFamilyComponent implements OnInit {

    familyId: number = undefined;
    editMode: boolean;
    editFamily: ProductsFamilyInstance | undefined = undefined;

    form: FormGroup;

    customValidationDict = {
        required: 'common.inputErrors.required',
        min: 'common.inputErrors.min'
    };

    constructor(
        private readonly _fb: FormBuilder,
        private readonly _font: FontService,
        private readonly _translate: TranslateService,
        private readonly _notification: NotificationService,
        private readonly _popup: PopupService,
        private readonly _spinner: OverlaySpinnerService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _familyService: ProductFamilyService,
    ) {
        this._font.addIcon(faArrowLeft, faInfoSquare);
    }

    async ngOnInit(): Promise<void> {
        this.form = this._fb.group({
            code: [null, [Validators.required]],
            description: [null, [Validators.required]],
        });

        this.familyId = parseInt(this._route.snapshot.paramMap.get('id'), 10);
        this.editMode = this._route.snapshot.data.editMode;
        if (isNaN(this.familyId)) {
            this.familyId = undefined;

            if (this.editMode) {
                this._translate.stream([
                    'common.error',
                    'productsFamily.createEdit.loadError',
                ]).subscribe(async (translations) => {
                    const title = translations['common.error']
                    const message = translations['productsFamily.createEdit.loadError'];
                    this.createToast('error', title, message);
                });
            }
        } else {
            await this.loadData();
        }
    }

    private async loadData() {
        try {
            this._spinner.show();

            this.editFamily = await this._familyService.getFamily(this.familyId);

            if (this.editFamily) {
                this.form.patchValue({
                    code: this.editFamily.code,
                    description: this.editFamily.description,
                });
            }
        } catch (err) {
            this.catchError(this._translate.instant('productsFamily.createEdit.loadError'), err);
        } finally {
            this._spinner.removeOverlay();
        }
    }

    async handleSaveClicked() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        try {
            this._spinner.show();

            let code = '';

            if (this.editMode) {
                const family: ProductsFamilyInstance = {
                    familyId: this.familyId,
                    code: this.form.get('code').value,
                    description: this.form.get('description').value,
                };
                code = family.code;
                await this._familyService.updateFamily(family);
            } else {
                const family: ProductsFamilyInstance = {
                    companyId: parseInt(localStorage.getItem('companyId')),
                    code: this.form.get('code').value,
                    description: this.form.get('description').value,
                }
                code = family.code;
                await this._familyService.createFamily(family);
            }

            this._translate.stream([
                'productsFamily.createEdit.saveTitle',
                'productsFamily.createEdit.saveMessage',
            ], { name: code }).subscribe(async (translations) => {
                const title = translations['productsFamily.createEdit.saveTitle'];
                const message = translations['productsFamily.createEdit.saveMessage'];
                this.createToast('check', title, message);
            });

            this._router.navigate(['/families']);
        } catch (err) {
            this.catchError(this._translate.instant('productsFamily.createEdit.error'), err);
        } finally {
            this._spinner.removeOverlay();
        }
    }

    async handleCancelClicked() {
        if (this.form.touched) {
            const code = this.editMode ? this.editFamily.code : this.form.get('code').value;

            this._translate.stream([
                'productsFamily.createEdit.closeWithoutSavingNewTitle',
                'productsFamily.createEdit.closeWithoutSavingNewMessage',
                'productsFamily.createEdit.closeWithoutSavingNewMessageGeneric',
                'productsFamily.createEdit.closeWithoutSavingEditTitle',
                'productsFamily.createEdit.closeWithoutSavingEditMessage',
                'productsFamily.createEdit.confirmButton',
                'productsFamily.createEdit.cancelButton',
            ], { name: code }).subscribe(async (translations) => {
                const title = translations[this.editMode
                    ? 'productsFamily.createEdit.closeWithoutSavingEditTitle'
                    : 'productsFamily.createEdit.closeWithoutSavingNewTitle'];
                const message = translations[this.editMode
                    ? 'productsFamily.createEdit.closeWithoutSavingEditMessage'
                    : (code
                        ? 'productsFamily.createEdit.closeWithoutSavingNewMessage'
                        : 'productsFamily.createEdit.closeWithoutSavingNewMessageGeneric')];

                const newButtons = {
                    primary: {
                        text: this._translate.instant('productsFamily.createEdit.saveExitButton'),
                    },
                    secondary: {
                        text: this._translate.instant('productsFamily.createEdit.exitButton'),
                    },
                    tertiary: {
                        text: this._translate.instant('productsFamily.createEdit.cancelButton'),
                    },
                };
                const editButtons = {
                    primary: {
                        text: this._translate.instant('productsFamily.createEdit.confirmButton'),
                    },
                    tertiary: {
                        text: this._translate.instant('productsFamily.createEdit.cancelButton'),
                    },
                };
                const buttons = this.editMode ? editButtons : newButtons;

                this.createPopup(title, message, 'error', buttons, async (button: PopupResult) => {
                    if (button == 'primary') {
                        if (this.editMode)
                            await this._router.navigate(['/families']);
                        else
                            await this.handleSaveClicked();
                    } else if (button == 'secondary') {
                        if (!this.editMode)
                            await this._router.navigate(['/families']);
                    }
                });
            });
        } else {
            // Do not need to confirm because the form was not touched
            await this._router.navigate(['/families']);
        }
    }

    private catchError(message: string, error: Error) {
        console.error(error);

        const config: NotificationConfig = {
            content: message,
            type: 'toast',
            style: 'error',
            timeout: 5000,
            position: 'right',
        };
        this._notification.show(config);
    }

    private createPopup(title: string, message: string, level: NotifyLevel, buttons: any, func: (button: PopupResult) => void) {
        const popupConfig: PopupConfig = {
            title: title,
            level: level,
            showCloseButton: true,
            visible: true,
            dragEnabled: false,
            bottomButtons: buttons,
        };
        const bodyConfig: PopupBodyConfig = {
            content: message,
        };
        this._popup.show(popupConfig, bodyConfig).subscribe(func);
    }

    private async createToast(style: NotificationStyle, title: string, message: string) {
        const config: NotificationConfig = {
            title: title,
            content: message,
            type: 'toast',
            style: style,
            timeout: 5000,
            position: 'right',
        };
        await this._notification.show(config);
    }

}
