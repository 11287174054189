import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CompanyInstance } from '../models/company.model';
import { PlantInstance } from '../models/plant.model';

@Injectable()
export class CompanyService {
    companies: CompanyInstance[];
    private changeCompany = new Subject<any>();
    changeCompanyEmitted$ = this.changeCompany.asObservable();        

    constructor(private _http: HttpService, private _config: ConfigService) { }

    async getCompanies(populateDevice?: boolean, populateModule?: boolean) {

        let populateDeviceEnabled = populateDevice || false;
        // Inserisce i moduli degli impianti/macchine se presenti
        let populateModuleEnabled = populateModule || false;

        let url = `${this._config.API.url}/company?populateDevice=${populateDeviceEnabled}&populateModule=${populateModuleEnabled}`;
        const response = await this._http.get<CompanyInstance[]>(url);

        this.companies = response.body;
        return this.companies;
    }

    async getCompany(companyId: number, populateDevice?: boolean) {

        let populateDeviceEnabled = populateDevice || false;

        const url = `${this._config.API.url}/company/${companyId}?populateDevice=${populateDeviceEnabled}`;
        
        const response = await this._http.get<CompanyInstance>(url);

        return response.body;
    }

    async getPlants(companyId: number) {
        const url = `${this._config.API.url}/company/plants/${companyId}`;
        const response = await this._http.get<PlantInstance[]>(url);

        return response.body;
    }

    async updateSatelliteUrl(companyId: number, satelliteUrl: string) {
        const url = `${this._config.API.url}/company/${companyId}?satelliteUrl=${satelliteUrl}`;
        const response = await this._http.put(url);

        return response.status;
    }

    emitChangeCompany(company: CompanyInstance) {
        this.changeCompany.next(company);
    }    
}
