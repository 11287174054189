import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DeviceService } from '../../../services/device.service';
import { CompanyService } from '../../../services/company.service';
import { UiService } from '../../../services/ui.service';
// import { CompanyService } from '../../../services/company.service';

import { UnipiInstance } from '../../../models/unipi.model';
import { CompanyInstance } from '../../../models/company.model';
import { PlantInstance } from '../../../models/plant.model';

@Component({
    selector: 'app-qr-dialog',
    templateUrl: './new-virtual-device-dialog.component.html',
    styleUrls: ['./new-virtual-device-dialog.component.scss']
})
export class NewVirtualDeviceDialogComponent implements OnInit {
    companies: CompanyInstance[];

    constructor(
        private _device: DeviceService,
        private _company: CompanyService,
        private _ui: UiService,
        private _ref: MatDialogRef<NewVirtualDeviceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    async ngOnInit() {
        this.companies = await this._company.getCompanies();
    }

    async onClose(result) {
        try {
            if (result === 'save')
                await this.saveDevice();

            this._ref.close();
        } catch (err) {
            this._ref.close(err);
        }
    }

    async saveDevice() {
        const deviceData = {
            label: this.data.label,
            sessionType: this.data.sessionType,
            isManual: true
        };


        if (this.data.company.id === undefined && !this.data.company.name) {
            this._ui.openSnackBar('Missing company data');
            return;
        }
        if (this.data.__companyType === 'existing') {
            deviceData['companyId'] = this.data.company.id;
            deviceData['plantId'] = this.data.plant ? this.data.plant.id : this.data.company.Plants[0].id;
        } else
            deviceData['company'] = {
                name: this.data.company.name,
                provider: this.data.provider
            };

        if (this.data.__createManager) {
            if (!!this.data.manager.email && !!this.data.manager.password) {
                deviceData['company'] = deviceData['company'] ? deviceData['company'] : {};
                deviceData['company'].manager = {
                    email: this.data.manager.email,
                    password: this.data.manager.password
                };
            } else {
                console.error('Missing manager creds');
                this._ui.openSnackBar('Missing manager credentials');
            }
        }

        await this._device.createDevice(deviceData);
        this._ui.openSnackBar('Virtual device created successfully');
    }
}
