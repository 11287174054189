import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UiService } from '../../../services/ui.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '../../ui/dialog/dialog.component';
import { CompanyService } from '../../../services/company.service';
import { CompanyInstance } from '../../../models/company.model';
import { AuthService } from '../../../services/auth.service';
import { UserRole } from '../../../models/user.model';
import { DeviceService } from '../../../services/device.service';
import { UserInstance } from '../../../models/user.model';
import { DeviceInstance } from '../../../models/device.model';
import { PlantInstance } from '../../../models/plant.model';
import { OperatorService } from '../../../services/operator.service';
import { OperatorInstance } from '../../../models/operator.model';
import { QualityControlDialogComponent } from '../../ui/quality-control-dialog/quality-control-dialog.component';
import { OperatorsDialogComponent } from '../../ui/operators-dialog/operators-dialog.components';


@Component({
    selector: 'app-users',
    templateUrl: './operators.component.html',
    styleUrls: ['./operators.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OperatorsComponent implements OnInit {
    columns = [];
    isAdmin = false;
    operators: OperatorInstance[];
    tmpOperators: OperatorInstance[];
    selectedCompany: CompanyInstance;
    selectedCompanyId: number;
    selectedPlant: PlantInstance;
    selectedPlantId: number;
    editRow: number;
    modified: boolean;
    USER_ROLE: typeof UserRole = UserRole;
    current: UserRole;
    devices: DeviceInstance[];
    plants: PlantInstance[];
    moduleActivated = false;
    lockRequest = false;
    private _subscriptions: Subscription[] = [];

    @ViewChild('name', { static: false }) nameTemplate: TemplateRef<any>;
    @ViewChild('surname', { static: false }) surnameTemplate: TemplateRef<any>;
    @ViewChild('code', { static: false }) codeTemplate: TemplateRef<any>;
    @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
    @ViewChild('addItem', { static: true }) addItemTemplate: TemplateRef<any>;
    @ViewChild('devicesTemplate', { static: true }) deviceTemplate: TemplateRef<any>;

    constructor(
        private _company: CompanyService,
        private _operators: OperatorService,
        private _device: DeviceService,
        private _ui: UiService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _dialog: MatDialog,
        private _translate: TranslateService,
        public auth: AuthService,
    ) { }

    async ngOnInit() {
        const translationSubscription =
            this._translate.stream([
                'operators.title',
                'users.columns.firstName',
                'users.columns.lastName',
                'users.columns.enabled',
                'orders-list.columns.code',
                'orders-list.columns.devices'
            ])
            .subscribe((translations) => {
                this._navbar.setTitle(translations['operators.title']);
                setTimeout(() => this._sidebar.setSelected('operators'));
                this.columns = [{
                    resizable: false,
                    canAutoResize: true,
                    width: 300,
                    maxWidth: 500,
                    name: translations['users.columns.firstName'],
                    prop: 'name',
                    cellTemplate: this.nameTemplate
                }, {
                    resizable: false,
                    canAutoResize: true,
                    width: 250,
                    maxWidth: 350,
                    name: translations['users.columns.lastName'],
                    prop: 'surname',
                    cellTemplate: this.surnameTemplate
                }, {
                    resizable: false,
                    canAutoResize: true,
                    width: 350,
                    maxWidth: 500,
                    name: translations['orders-list.columns.code'],
                    prop: 'code',
                    cellTemplate: this.codeTemplate
                }, {
                    resizable: false,
                    canAutoResize: true,
                    width: 150,
                    maxWidth: 250,
                    name: translations['orders-list.columns.devices'],
                    prop: 'Devices.length',
                    cellTemplate: this.deviceTemplate
                },
                {
                    resizeable: false,
                    canAutoResize: true,
                    prop: 'id',
                    headerTemplate: this.addItemTemplate,
                    cellTemplate: this.actionsTemplate
                }];
            });
        this._subscriptions.push(translationSubscription);

        this.isAdmin = this.auth.user.role === UserRole.admin;
        this.selectedCompanyId = Number(localStorage.getItem('companyId'));
        this.lockRequest = true;
        if (this.isAdmin) {
            await this.companyChanged(await this._company.getCompany(this.selectedCompanyId));
        } else {
            // if is user get company detailt to get plants
            this.selectedCompany = await this._company.getCompany(this.selectedCompanyId);
            // set plants
            this.plants = this.selectedCompany.Plants;
            await this._handleBuildPlantArrayObject(true);
            await this.plantChanged();
        }

        const companyChangeSubscription =
            this._company.changeCompanyEmitted$.subscribe(company => {
                if(this.isAdmin){
                    this.companyChanged(company);
                }
            });
        this._subscriptions.push(companyChangeSubscription);
    }

    ngOnDestroy(): void {
        // Unsubscribe all subscriptions to avoid memory leaks
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
            array.splice(index, 1);
        });
    }

    createOrUpdateOperator(operatorId?: number) {
        let _operator: OperatorInstance = null;
        if (!operatorId) {
            _operator = {
                id: null,
                name: '',
                surname: '',
                code: null,
                Devices: []
            };
        } else {
            _operator = this.tmpOperators.find(o => o.id === operatorId);
        }

        const ref = this._dialog.open(OperatorsDialogComponent, {
            width: '800px',
            height: '600px',
            data: {
                operator: _operator,
                plantDevices: this.devices
            }
        });

        ref.afterClosed().subscribe(async (operator: OperatorInstance) => {
            try {
                if (operator) {
                    if (!operator.id) {
                        // Create
                        const deviceIds: number[] = operator.Devices.length ? operator.Devices.map(d => d.id) : [];
                        operator = await this._operators.createOperator(
                            operator.name,
                            operator.surname,
                            operator.code,
                            this.selectedPlantId,
                            deviceIds
                        );
                        if (typeof operator === 'number') {
                            this._ui.openSnackBar(this._translate.instant('operators.error-save'));
                            return;
                        }
                        this.operators.unshift(operator);
                        this.tmpOperators = this.clone(this.operators);
                        this._ui.openSnackBar(this._translate.instant('operators.saved'));
                    } else {
                        const deviceIds: number[] = operator.Devices.length ? operator.Devices.map(d => d.id) : [];
                        operator = await this._operators.updateOperator(
                            operator.id,
                            operator.name,
                            operator.surname,
                            operator.code,
                            deviceIds
                        );
                        if (typeof operator === 'number') {
                            this._ui.openSnackBar(this._translate.instant('operators.error-save'));
                            return;
                        }
                        const opIndex = this.tmpOperators.findIndex(op => op.id === operator.id);
                        this.tmpOperators.splice(opIndex, 1, operator);
                        this.tmpOperators = this.clone(this.tmpOperators);
                        this._ui.openSnackBar(this._translate.instant('operators.saved'));
                    }
                }
            } catch (err) {
                console.error(err);
                this._ui.openSnackBar(this._translate.instant('operators.error-save'));
            }
        });
    }

    async companyChanged(company: CompanyInstance) {
        this.selectedCompany = company;
        this.selectedCompanyId = company.id;
        this.plants = company.Plants;
        const isSelectedPlantInCurrentCompany = this.selectedPlantId ?
            company.Plants.find((p) => p.id === this.selectedPlantId) : false;
        await this._handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany ? true : false);
        await this.plantChanged();
    }

    async _handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany: boolean) {
        // set plant
        if (!this.selectedPlantId || !isSelectedPlantInCurrentCompany) {
            this.selectedPlant = this.plants[0];
        } else if (this.plants.length > 0) {
            this.selectedPlant = this.plants.find((p) => p.id === this.selectedPlantId);
        }
    }

    async plantChanged() {
        if (this.selectedPlant) {
            this.selectedPlantId = this.selectedPlant.id;
            localStorage.setItem('plantId', this.selectedPlantId.toString());

            if (this.selectedPlant.PlantModule && this.selectedPlant.PlantModule.operatorsLogin) {
                this.moduleActivated = true;
                this.operators = await this._operators.getOperators(this.selectedPlantId);
                this.operators = this.operators.filter((operator) => {
                    return operator.id > 0;
                });

                this.devices = await this._device.getDevicesByPlant(this.selectedCompanyId, this.selectedPlantId);

                this.tmpOperators = this.clone(this.operators);
                this.editRow = null;
                this.modified = false;
            } else {
                this.moduleActivated = false;
            }
        }
        this.lockRequest = false;
    }

    /**
     * Retrieve the plant list about company
     *
     * @param {number} companyId
     * @returns
     * @memberof UsersComponent
     */
    async getPlants (companyId: number) {
        return await this._company.getPlants(companyId);
    }

    isEditRow(rowIndex: number) {
        return this.editRow === rowIndex;
    }

    isEditMode(rowIndex: number) {
        return (this.editRow) && !this.isEditRow(rowIndex);
    }

    setModified() {
        this.modified = true;
    }

    async deleteOperator(operatorId?: number) {
        const _operator = this.tmpOperators.find(o => o.id === operatorId);

        const ref = this._dialog.open(DialogComponent, {
            width: '500px',
            data: {
                title: this._translate.instant('operators.dialog.title'),
                message: this._translate.instant('operators.dialog.text', { operator: _operator.name + ' ' + _operator.surname }),
                disabled: 'no'
            }
        });

        ref.afterClosed().subscribe(async (result) => {
            try {
                if (result) {
                    const status = await this._operators.deleteOperator(_operator.id);
                    if (status === 200) {
                        const opIndex = this.tmpOperators.findIndex(op => op.id === _operator.id);
                        this.tmpOperators.splice(opIndex, 1);
                        this.tmpOperators = this.clone(this.tmpOperators);
                        this._ui.openSnackBar(this._translate.instant('operators.deleted'));
                    } else {
                        this._ui.openSnackBar(this._translate.instant('operators.error-delete'));
                    }
                }
            } catch (err) {
                console.error(err);
                this._ui.openSnackBar(this._translate.instant('operators.error-delete'));
            }
        });
    }

    openDialogDevices(operator: OperatorInstance, index: number) {
        const labels = operator.Devices.map((d) => d.label);
        let title = 'orders-list.columns.devices';
        if (labels.length === 1) {
            title = 'orders-list.columns.device';
        }
        this._dialog.open(QualityControlDialogComponent, {
            width: '600px',
            height: '600px',
            data: {
                key: title,
                values: labels,
                needFilterBar: true
            }
        });
    }

    clone(array: any) {
        return array.map(x => Object.assign({}, x));
    }

    updateSelectedUserPlant(user: UserInstance) {
        user.Plants = [];
        if (user && user.plant && user.plant.id !== -1 && this.plants && this.plants.length > 0) {
            user.Plants[0] = this.plants.find(p => p.id === user.plant.id);
        }
    }
}
