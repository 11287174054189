import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NotificationConfig, NotificationService } from '@vapor/angular-ui';
import { SelectComponent } from '@vapor/angular-ui/select';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OrderStatus } from '../../../models/order-device.model';
import { OrdersListService } from '../../../services/ordersList.service';
import { OrderListInterface } from '../../../models/orders-groups-view.model';

@Component({
    selector: 'app-order-info',
    templateUrl: './order-info.component.html',
    styleUrls: ['./order-info.component.scss'], 
    providers: [
        NotificationService,
    ],    
})
export class OrderInfoComponent implements OnInit, OnChanges {

    @ViewChild('content', { static: false }) contentTemplate: TemplateRef<any>;
    @ViewChild('bottomBar', { static: false }) bottomBarTemplate: TemplateRef<any>;
    @ViewChild('headerTemplate', { static: false }) headerTemplate: TemplateRef<any>;
    @ViewChild('select', { static: false }) select: TemplateRef<SelectComponent>;

    plannedStatuses = [];
    productionStatuses = [];
    @Input() order?: OrderListInterface;
    @Input() toBeProduced?: number;
    private orderStatus?: OrderStatus;
    newOrderStatus?: any = 1 ;
    form: FormGroup;
    show: boolean = false;
    constructor(
        private readonly _translate: TranslateService,
        private _ordersListService: OrdersListService,
        private readonly _notification: NotificationService,
    ) { }

    get submitDisabled(): boolean {
        return this.newOrderStatus === this.order?.status;
    }

    ngOnInit(): void {
        this._translate.stream([
            'orders-list.status.draft',
            'orders-list.status.planned',
            'orders-list.status.running',
            'orders-list.status.suspended',
            'orders-list.status.completed',            
        ]).subscribe((translations) => {
            this.plannedStatuses = [
                { id: OrderStatus.draft, val: translations['orders-list.status.draft'] },
                { id: OrderStatus.planned, val: translations['orders-list.status.planned'] }
            ];
            this.productionStatuses = [
                { id: OrderStatus.running, val: translations['orders-list.status.running'], disabled: this.order.status != OrderStatus.running },
                { id: OrderStatus.suspended, val: translations['orders-list.status.suspended'], disabled: this.order.status == OrderStatus.running },
                { id: OrderStatus.completed, val: translations['orders-list.status.completed'], disabled: this.order.status == OrderStatus.running },
            ];
        })
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.order?.currentValue?.status) {
            const currentValue = changes.order.currentValue;
            this.newOrderStatus = currentValue.status;
            if (this.productionStatuses.length == 3) {
                this.productionStatuses[0].disabled = currentValue.status != OrderStatus.running;
                this.productionStatuses[1].disabled = currentValue.status == OrderStatus.running;
                this.productionStatuses[2].disabled = currentValue.status === OrderStatus.running;
            }
        }
    }

    async editClicked() {
        const result = await this._ordersListService.editOrderStatus(this.order.id, this.newOrderStatus);
        if(String(result).charAt(0) !== '2'){
            this._translate.stream([
                'orders-list.orderInfo.editOrderStatusError',
            ], { orderCode: this.order.code}).subscribe((translations) => {
                const config: NotificationConfig = {
                    content: translations['orders-list.orderInfo.editOrderStatusError'],
                    type: 'toast',
                    style: 'error', 
                    timeout: 5000,
                    position: 'right',
                };
                this._notification.show(config);
            });
        } else {
            this._translate.stream([
                'orders-list.orderInfo.editOrderStatusMessage',
            ], { orderCode: this.order.code}).subscribe((translations) => {
                const config: NotificationConfig = {
                    content: translations['orders-list.orderInfo.editOrderStatusMessage'],
                    type: 'toast',
                    style: 'check',
                    timeout: 5000,
                    position: 'right',
                };
                this._notification.show(config);
                this.order.status = this.newOrderStatus;
            });
        }
    }

    plannedStatusChanged(status: any): void{
        this.newOrderStatus = status.id;
    }
    
    productionStatusChanged(status: any): void{
        if(status.disabled) return;
        if(this.order.status == 3) return;
        this.newOrderStatus = status.id;
    }

    toJSON(data: string){
        try {
            return JSON.parse(data);
        } catch (error) {
            console.error(error)
        }
    }
}
