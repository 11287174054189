import { Injectable } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Injectable()
export class ThemeService {

  constructor(private titleService: Title) {
    let theme = 'team-system';
    this.setStyle('theme', `/assets/css/${theme}-theme.css`);
    this.setFavicon(theme);
    this.setTitle();
  }

  setStyle(key: string, href: string) {
    getLinkElementForKey(key).setAttribute('href', href);
  }

  faviconIds: string[] = [
    "apple-icon-57",
    "apple-icon-60",
    "apple-icon-72",
    "apple-icon-76",
    "apple-icon-114",
    "apple-icon-120",
    "apple-icon-144",
    "apple-icon-152",
    "apple-icon-180",
    "android-icon-192",
    "favicon-32",
    "favicon-96",
    "favicon-16",
    "manifest"
  ];

  setFavicon(theme: string) {
    let faviconDir = '/favicons';

    let directory= theme.replace('-', '_');

    this.faviconIds.forEach((id) => {
      let favIcon: HTMLLinkElement = document.querySelector('#' + id);
      let basePath = faviconDir;
      if(favIcon) {
        let change = false;
        if(directory === '') {
          let matches = favIcon.href.match(/(?<=favicons\/)(\w+)(?=\/)/);
          change = matches != null && matches.length > 0
          if(change) {
            basePath += '/' + matches[0] + '/';
          }
        } else {
          change = !favIcon.href.includes(directory);
        }
        if(change) {
          favIcon.href = favIcon.href.replace(basePath, faviconDir + '/' + directory);
        }
      }
    });
  }

  setTitle() {
    this.titleService.setTitle("CMS MES Industry 4.0");
  }

}

function getLinkElementForKey(key: string) {
  return getExistingLinkElementByKey(key) || createLinkElementWithKey(key);
}

function getExistingLinkElementByKey(key: string) {
  return document.head.querySelector(`link[rel="stylesheet"].${getClassNameForKey(key)}`);
}

function createLinkElementWithKey(key: string) {
  const linkEl = document.createElement('link');
  linkEl.setAttribute('rel', 'stylesheet');
  linkEl.setAttribute('type', 'text/css');
  linkEl.classList.add(getClassNameForKey(key));
  document.head.appendChild(linkEl);
  return linkEl;
}

function getClassNameForKey(key: string) {
  return `style-manager-${key}`;
}

