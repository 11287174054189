import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductionUnitsInstance } from '../../../models/production-units.model';

@Component({
    selector: 'units-list-cell',
    templateUrl: './units-list-cell.component.html',
    styleUrls: ['./units-list-cell.component.scss']
})
export class UnitsListCellComponent implements OnInit {

    @Input() tplKey: any;
    @Input() data: ProductionUnitsInstance;
    @Output() editUnitClicked = new EventEmitter<ProductionUnitsInstance>();
    @Output() deleteUnitClicked = new EventEmitter<ProductionUnitsInstance>();

    constructor() { }

    ngOnInit(): void {
    }

    get decimalRepresentation(): string {
        if (this.data.decimal == 0) {
            return '';
        }

        const decimalFormat = "0".repeat(this.data.decimal);
        return ` (,${decimalFormat})`;
    }

    onEditClicked(object: ProductionUnitsInstance) {
        this.editUnitClicked.emit(object);
    }

    onDeleteClicked(object: ProductionUnitsInstance) {
        this.deleteUnitClicked.emit(object);
    }

}
