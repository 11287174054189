import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { ControlIstance } from '../models/control.model';
import { ControlSchedulingIstance } from '../models/control-scheduling.model';


@Injectable()
export class ControlsService {

    controls: ControlIstance[];
    controlScheduling: ControlSchedulingIstance[];

    constructor(private _http: HttpService, private _config: ConfigService) { }

    async getControls(companyId: number, plantId: number, productId?: number) {
        let url = `${this._config.API.url}/controls?companyId=${companyId}&plantId=${plantId}`;

        if (productId) { url += `&productId=${productId}`; }

        const response = await this._http.get<ControlIstance[]>(url);

        this.controls = response.body;
        return this.controls;
    }

    async get(controlId: number) {
        const url = `${this._config.API.url}/controls/${controlId}`;

        const response = await this._http.get<ControlIstance>(url);
        const control = response.body;

        return control;
    }

    async create(companyId: number, plantId: number) {
        const url = `${this._config.API.url}/control?companyId=${companyId}&plantId=${plantId}`;

        const response = await this._http.post<ControlIstance>(url);

        const check = response.body;
        this.controls.push(check);

        return check;
    }

    async update(controlId: number, data: object): Promise<ControlIstance> {
        const url = `${this._config.API.url}/controls/${controlId}`;

        const response = await this._http.put<ControlIstance>(url, data);

        return response.body;

    }

    async duplicate(controlId: number) {
        const url = `${this._config.API.url}/controls/${controlId}/duplicate`;

        const response = await this._http.put<ControlIstance>(url);

        return response.body;
    }

    async delete(controlId: number) {
        const url = `${this._config.API.url}/controls/${controlId}`;

        const response = await this._http.delete(url);

        return response;
    }
}
