import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TagKindEnum } from '@vapor/angular-ui';

import { OrderStatus } from '../../../models/order-device.model';
import { OrderListInterface } from '../../../models/orders-groups-view.model';

@Component({
    selector: 'app-orders-list-cell',
    templateUrl: './orders-list-cell.component.html',
    styleUrls: ['./orders-list-cell.component.scss'],
})
export class OrdersListCellComponent implements OnInit {
    @Input() tplKey: any;
    @Input() data: any;
    @Input() level: number;
    @Input() selectionEnabled: boolean = false;
    @Input() selectedDevicesId: number[] = [];
    @Input() infoEnabled: boolean = true;
    @Input() editEnabled: boolean = true;
    @Input() deleteEnabled: boolean = true;

    @Output() editOrderClicked = new EventEmitter<OrderListInterface>();
    @Output() editOrdersGroupClicked = new EventEmitter<OrderListInterface>();
    @Output() deleteOrderClicked = new EventEmitter<OrderListInterface>();
    @Output() deleteOrdersGroupClicked = new EventEmitter<OrderListInterface>();
    @Output() ordersGroupInfoClicked = new EventEmitter<OrderListInterface>();
    @Output() orderInfoClicked = new EventEmitter<OrderListInterface>();
    @Output() rankChanged = new EventEmitter<{order: OrderListInterface, event: any}>();
    @Output() rowSelected = new EventEmitter<OrderListInterface>();
    @Output() rowUnselected = new EventEmitter<OrderListInterface>();

    mapStatusTagClass: { [key in OrderStatus]: TagKindEnum } = {
        [OrderStatus.draft]: TagKindEnum.MaximumPurple,
        [OrderStatus.planned]: TagKindEnum.BlueSapphire,
        [OrderStatus.running]: TagKindEnum.RoyalFuchsia,
        [OrderStatus.suspended]: TagKindEnum.RussianViolet,
        [OrderStatus.completed]: TagKindEnum.IslamicGreen,
    };
    mapStatusTagTitle: { [key: number]: string } = {};
    mapStatusToClass: { [key in OrderStatus]: string } = {
        [OrderStatus.draft]: "draft",
        [OrderStatus.planned]: "planned",
        [OrderStatus.running]: "running",
        [OrderStatus.suspended]: "suspended",
        [OrderStatus.completed]: "completed",
    };

    constructor(
        private _translate: TranslateService,
    ) {
    }

    ngOnInit(): void {
        this._translate.stream([
            'orders-list.status.draft',
            'orders-list.status.planned',
            'orders-list.status.running',
            'orders-list.status.suspended',
            'orders-list.status.completed',
        ]).subscribe((translations) => {
            this.mapStatusTagTitle = {
                [OrderStatus.draft]: translations['orders-list.status.draft'],
                [OrderStatus.planned]: translations['orders-list.status.planned'],
                [OrderStatus.running]: translations['orders-list.status.running'],
                [OrderStatus.suspended]: translations['orders-list.status.suspended'],
                [OrderStatus.completed]: translations['orders-list.status.completed'],
            };
        });
    }

    onOrderInfoClicked(order: OrderListInterface) {
        this.orderInfoClicked.emit(order);
    }

    onOrdersGroupInfoClicked(orderGroup: OrderListInterface) {
        this.ordersGroupInfoClicked.emit(orderGroup);
    }    

    onEditOrderClicked(order: OrderListInterface) {
        this.editOrderClicked.emit(order);
    }

    onEditOrdersGroupClicked(order: OrderListInterface) {
        this.editOrdersGroupClicked.emit(order);
    }

    deleteOrderClick(order: OrderListInterface) {
        this.deleteOrderClicked.emit(order);
    }

    deleteOrderGroupClick(orderGroup: OrderListInterface) {
        this.deleteOrdersGroupClicked.emit(orderGroup);
    }

    onRankChanged(order: OrderListInterface, event: any) {
        this.rankChanged.emit({order, event});
    }

    onSelectionChanged(order: OrderListInterface, value: boolean) {
        order.selected = value;

        if (value)
            this.rowSelected.emit(this.data);
        else
            this.rowUnselected.emit(this.data);
    }
}