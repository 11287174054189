import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FontService, NotificationConfig, NotificationService, NotificationStyle, OverlaySpinnerService} from '@vapor/angular-ui';
import { AuthService } from '../../../services/auth.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { Subscription } from 'rxjs';
import { UserRole } from '../../../models/user.model';
import { CompanyInstance } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { ProductFamilyService } from '../../../services/productFamily.service';
import { ProductsFamilyInstance } from '../../..//models/productsFamily.model';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { GenericDialogComponentData, GenericDialogComponentModes } from '../../ui/generic-dialog/generic-dialog.component';
import { Router } from '@angular/router';


@Component({
    selector: 'families',
    templateUrl: './families.component.html',
    styleUrls: ['./families.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        NotificationService,
    ],    
})

export class FamiliesComponent implements OnInit {

    isAdmin: boolean;
    isManager: boolean;
    selectedCompany: CompanyInstance;
    selectedCompanyId: number;
    _subscriptions: Subscription[] = [];
    familiesColumns: TsTreeListColumn[] = [];
    familiesListHeight = 'calc(100vh - 220px)';
    families: ProductsFamilyInstance[];
    selectedFamily: ProductsFamilyInstance = null;
    showModal: boolean = false;
    modalData: GenericDialogComponentData;

    @ViewChild('familiesList', { static: false, read: ElementRef }) familiesListRef!: ElementRef;
    
    constructor(
        private _font: FontService,
        private _auth: AuthService,
        private _navbar: NavbarService,      
        private _sidebar: SidebarService,  
        private _spinner: OverlaySpinnerService,
        private _router: Router,
        private readonly _notification: NotificationService,
        private _translate: TranslateService,
        private _company: CompanyService,
        private _family: ProductFamilyService,
    ) { 
        this._font.addIcon(faPencil, faTrashAlt);
    }

    async ngOnInit() {
        this._spinner.show();
        try {
            const translationSubscription = this._translate.stream([
                'products.title',
                'products.search',
                'productsFamily.columns.code',
                'productsFamily.columns.description',
            ]).subscribe((translations) => {
                this._navbar.setTitle(translations['products.families.title']);
                setTimeout(() => this._sidebar.setSelected('families'));

                const defaultColumn: TsTreeListColumn = {
                    dataField: '',
                    headerPlaceholder: translations['products.search'],
                    allowFiltering: true,
                    allowEditing: false,
                    allowSorting: true        
                };
                this.familiesColumns =
                    [
                        { // id
                            caption: 'id',
                            dataField: 'id',
                            showColumn: false,
                        },
                        { // code
                            ...defaultColumn,
                            caption: translations['productsFamily.columns.code'],
                            dataField: 'code',
                            dataType: 'string',
                            width: 302,
                        },
                        { // description
                            ...defaultColumn,
                            caption: translations['productsFamily.columns.description'],
                            dataField: 'description',
                            dataType: 'string',
                        },
                        { // actions
                            ...defaultColumn,
                            caption: '',
                            fixedPosition: 'right',
                            alignment: 'center',
                            width: 110,
                            allowFiltering: false,
                            allowResizing: false,
                            allowFixing: false,
                            allowSorting: false,
                            cellTemplate: 'tplActions',
                        },
                    ];                
            });
            this._subscriptions.push(translationSubscription);

            this.isAdmin = this._auth.user.role === UserRole.admin;
            this.isManager = this._auth.user.role === UserRole.manager;
            this.selectedCompanyId = Number(localStorage.getItem('companyId'));
            if (this.isAdmin || this.isManager) {
                await this.companyChanged(await this._company.getCompany(this.selectedCompanyId));
            } else {
                this.selectedCompany = await this._company.getCompany(this.selectedCompanyId);
            }
            const companyChangeSubscription =
            this._company.changeCompanyEmitted$.subscribe(company => {
                if (this.isAdmin) {
                    this.companyChanged(company, true);
                }
            });
        this._subscriptions.push(companyChangeSubscription);            
            this._spinner.removeOverlay();
        } catch (err) {
            this.catchError(err);
        } finally {
            this._spinner.removeOverlay();
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    async companyChanged(company: CompanyInstance, showSpinner: boolean = false) {
        if (!company) {
            return;
        }
        this.selectedCompany = company;
        this.selectedCompanyId = company.id;

        if (showSpinner) this._spinner.show();
        try {
            this.families = await this._family.getFamilies(this.selectedCompanyId);
        } catch(err) {
            console.warn(err);
        } finally {
            if (showSpinner) this._spinner.removeOverlay(); 
        }
    }

    onNewfamilyClick() {
        this._router.navigate(['families/new']);
    }

    onDeleteFamilyClicked(family: ProductsFamilyInstance) {
        this.selectedFamily = family;
        this.modalData = {
            title:  this._translate.instant('productsFamily.delete.title'),
            message: this._translate.instant('productsFamily.delete.text', { name: family.code }),
            mode: GenericDialogComponentModes.delete
        };        
        this.showModal = true;
    }
    
    async onDeleteModalClicked(): Promise<void> {
        try {
            this._spinner.show();
            this.showModal = false;
            const result = await this._family.deleteFamily(this.selectedFamily.id);
            const deleted = result === 200;
            this.createToast(deleted);
        } catch (error) {
            this.createToast(false);
        } finally {
            this._spinner.removeOverlay();            
        }
        this.ngOnInit();
    }

    onCancelModalClicked() {
        this.showModal = false;
    }

    onEditFamilyClicked(family: ProductsFamilyInstance) {
        this._router.navigate([`families/edit/${family.id}`]);   
    }

    private async createToast(deleted: boolean): Promise<void> {
        let title: string;
        let message: string;
        let style: NotificationStyle = deleted ? 'check' : 'error';
        if (deleted) {
            message = this._translate.instant('productsFamily.delete.successText',{name: this.selectedFamily.code});
            title = this._translate.instant('productsFamily.delete.successTitle');
        } else if (!deleted) {
            message = this._translate.instant('productsFamily.deletedMessageErrorText');
            title = this._translate.instant('productsFamily.deletedMessageErrorTitle');
        }
        const config: NotificationConfig = {
            title: title,
            content: message,
            type: 'toast',
            style: style,
            timeout: 5000,
            position: 'right',
        };
        await this._notification.show(config);
    }    

    private catchError(error: string) {
        const config: NotificationConfig = {
            content: error,
            type: 'toast',
            style: 'error', 
            timeout: 5000,
            position: 'right',
        };
        this._notification.show(config);
    }
}
