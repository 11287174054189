import { Injectable } from '@angular/core';

import { HttpService } from './http.service';
import { ConfigService } from './config.service';
import { OrdersGroupsViewInstance } from '../models/orders-groups-view.model';
import { OrderGroupsInstance, QuantityDistribution, TimeDistribution } from '../models/order-group.model';
import { OrderStatus } from '../models/order-device.model';
import { OrderInstance } from '../models/order.model';

@Injectable({
    providedIn: 'root'
})
export class OrdersListService {
    private readonly _baseUrl = 'orders';

    constructor(
        private _http: HttpService,
        private _config: ConfigService,
    ) { }

    async fetchAll(plantId: number, inProduction: boolean, dateFrom?: Date, dateTo?: Date, page?: number, size?: number) {
        let url = `${this._config.API.urlV2}/${this._baseUrl}/fetchAll/?plantId=${plantId}`;

        if (dateFrom) {
            url += `&dateFrom=${dateFrom.toISOString()}`;
        }
        if (dateTo) {
            url += `&dateTo=${dateTo.toISOString()}`;
        }
        url += `&inProduction=${inProduction}`;
        if (page) {
            url += `&page=${page}`;
        }
        if (size) {
            url += `&size=${size}`;
        }

        return this._http.get<OrdersGroupsViewInstance[]>(url).then(response => {
            return response.body;
        }).catch(error => {
            console.error(error);
            return [];
        })
    }

    async deleteOrderGroup(groupId: number, deleteOrders: boolean) {
        let url = `${this._config.API.urlV2}/orderGroups/${groupId}`;
        const body = {deleteOrders: deleteOrders};
        return this._http.delete(url, body).then(response => {
            return response.status;
        })
    }
    
    async editOrderStatus(orderId: number, status: OrderStatus) {
        let url = `${this._config.API.urlV2}/order/status`;
        return this._http.put(url,{
            orderId: orderId,
            status: status
        }).then(response => {
            return response.status;
        }) 
    }

    async editOrderGroupStatus(groupId: number, status: OrderStatus) {
        let url = `${this._config.API.urlV2}/order/status`;
        return this._http.put(url,{
            groupId: groupId,
            status: status
        }).then(response => {
            return response.status;
        })
    }

    async editOrderRank(orderId: number, groupId: number, rank: number) {
        let url = `${this._config.API.urlV2}/ordersRank`;

        return this._http.put(url,{
            orderId: orderId,
            groupId: groupId,
            rank: rank
        }).then(response => {
            return response;
        }).catch(error => {
            console.error(error);
            return [];
        }); 
    }

    async createGroup(
        plantId: number,
        orderIds: number[],
        code: string,
        date: Date,
        quantityDistribution: QuantityDistribution,
        timeDistribution: TimeDistribution,
        status: OrderStatus,
        jsonInfo?: object,
    ): Promise<OrderGroupsInstance> {
        const url = `${this._config.API.urlV2}/orderGroups`;

        try {
            const data = {
                plantId,
                orderIds,
                code,
                date,
                quantityDistribution,
                timeDistribution,
                status,
                jsonInfo,
            };
            const response = await this._http.post<OrderGroupsInstance>(url, data);
            return response.body;
        } catch (err) {
            return err.status;
        }
    }

    editGroup(
        groupId: number,
        code: string,
        date: Date,
        quantityDistribution: QuantityDistribution,
        timeDistribution: TimeDistribution,
        status: OrderStatus,
        jsonInfo?: object,
    ) {
        const url = `${this._config.API.urlV2}/orderGroups/${groupId}`;

        return this._http.put(url, {
            code,
            date,
            quantityDistribution,
            timeDistribution,
            status,
            jsonInfo,
        }).then(response => {
            return response;
        });
    }

    association(orderIds: number[], groupId: number) {
        const url = `${this._config.API.urlV2}/orderGroups/association`;

        return this._http.patch(url,{
            orderIds: orderIds,
            groupId: groupId,
        }).then(response => {
            return response;
        });
    }

    disassociation(orderIds: number[]) {
        const url = `${this._config.API.urlV2}/orderGroups/disassociation`;

        return this._http.patch(url,{
            orderIds: orderIds
        }).then(response => {
            return response;
        }); 
    }

    async getOrder(id: number): Promise<OrderInstance> {
        const url = `${this._config.API.urlV2}/${this._baseUrl}/${id}`;
        return this._http.get<OrderInstance[]>(url).then(response => {
            return response.body;
        }).catch(error => {
            console.error(error);
            return null;
        })
    }

}
