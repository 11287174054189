import { Injectable } from '@angular/core';

import { HttpService } from './http.service';
import { ConfigService } from './config.service';

import { ProcessDataInstance } from '../models/process-data.model';

@Injectable()
export class ProcessDataService {
    processData: ProcessDataInstance[];

    constructor(
        private _http: HttpService,
        private _config: ConfigService
    ) { }

    async getAll(deviceId: number, enabled?: boolean): Promise<ProcessDataInstance[]> {
        let url = `${this._config.API.url}/processDataConf?`;

        if (deviceId) url += `&deviceId=${deviceId}`;
        if (enabled) url += `&enabled=${enabled}`;

        return this._http.get<ProcessDataInstance[]>(url).then( response => {
            return response.body;
        }).catch( error => {
            //TODO: Handle error with UI message
            console.log(error);
            return [];
        });
    }

    async getById(processDataId: number): Promise<ProcessDataInstance> {
        const url = `${this._config.API.url}/processDataConf/${processDataId}`;
        
        return this._http.get<ProcessDataInstance>(url).then( response => {
            return response.body;
        }).catch( error => {
            //TODO: Handle error with UI message
            console.log(error);
            return null;
        });
    }

    async create(deviceId: number, processData: ProcessDataInstance): Promise<ProcessDataInstance> {
        const url = `${this._config.API.url}/processDataConf`;

        return this._http.post<ProcessDataInstance>(url, {
            code: processData.code,
            description: processData.description,
            deviceId: deviceId,
            valueType: processData.valueType || null
        }).then( response => {
            return response.body;
        }).catch( error => {
            //TODO: Handle error with UI message
            console.log(error);
            return null;
        });
    }

    async update(processData: ProcessDataInstance): Promise<ProcessDataInstance> {
        const url = `${this._config.API.url}/processDataConf/${processData.id}`;
        
        return this._http.put<ProcessDataInstance>(url, {
            code: processData.code,
            description: processData.description,
            valueType: processData.valueType || null
        }).then( response => {
            return response.body;
        }).catch( error => {
            //TODO: Handle error with UI message
            console.log(error);
            return null;
        });
    }

    async delete(processDataId: number) {
        const url = `${this._config.API.url}/processDataConf/${processDataId}`;
        
        return this._http.delete(url).then( response => {
            return response.status;
        }).catch( error => {
            //TODO: Handle error with UI message
            console.log(error);
            return null;
        });
    }
}
