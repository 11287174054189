import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { UnitInstance } from '../models/task.model';


@Injectable()
export class UnitService {

    constructor(private _http: HttpService, private _config: ConfigService) { }

    async get(): Promise<UnitInstance[]> {
        let url = `${this._config.API.url}/units`;

        const response = await this._http.get<UnitInstance[]>(url);

        return response.body;
    }
}
