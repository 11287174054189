import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShiftsComponent } from '../../pages/work-shift/work-shift.component';

@Component({
    selector: 'app-qr-dialog',
    templateUrl: './calendar-dialog.component.html',
    styleUrls: ['./calendar-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CalendarDialogComponent {
    copyYear = 0; // 0 copy one week; 1 copy util the end of the year

    constructor(
        private _ref: MatDialogRef<ShiftsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    onClose(result) {
        try {
            if (result === 'save') {
                this._ref.close(this.copyYear);
            } else {
                this._ref.close();
            }
        } catch (err) {
            this._ref.close(err);
        }
    }

}
