import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { PlantModuleInstance } from '../models/plant-module.model';
import { DeviceModuleInstance } from '../models/device-module.model';

@Injectable()
export class ModulesService {
    plantModules: PlantModuleInstance;
    deviceModules: DeviceModuleInstance;

    constructor(private _http: HttpService, private _config: ConfigService) {}

    async createDeviceModule(data: Object): Promise<DeviceModuleInstance> {
        const url = `${this._config.API.url}/modules/device`;
        const response = await this._http.post<DeviceModuleInstance>(url, data);

        return response.body;
    }

    async udpatePlantModule(plantModuleId: number, data: Object): Promise<PlantModuleInstance> {
        const url = `${this._config.API.url}/modules/plant/${plantModuleId}`;
        const response = await this._http.put<PlantModuleInstance>(url, data);

        return response.body;
    }

    async udpateDeviceModule(deviceModuleId: number, data: Object): Promise<DeviceModuleInstance> {
        const url = `${this._config.API.url}/modules/device/${deviceModuleId}`;
        const response = await this._http.put<DeviceModuleInstance>(url, data);

        return response.body;
    }

    async deleteDeviceModule(deviceModuleId): Promise<boolean> {
        const url = `${this._config.API.url}/modules/device/${deviceModuleId}`;
        const response = await this._http.delete(url);

        return response.body as boolean;
    }
}