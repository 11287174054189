import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Resource, ResourceType } from '../../../models/resources.model';

@Component({
    selector: 'resource-list-cell',
    templateUrl: './resource-list-cell.component.html',
    styleUrls: ['./resource-list-cell.component.scss'],
})
export class ResourceListCellComponent implements OnInit {
    @Input() tplKey: any;
    @Input() data: Resource;
    @Output() editResourceClicked = new EventEmitter<object>();
    @Output() resourceToggle = new EventEmitter<object>();
    resourceString: string;

    constructor(
        private _translate: TranslateService,
    ) {
    }
    
    ngOnInit(): void {
        this._translate.stream([
            'resources.device',
            'resources.workcenter',
            'resources.department'
        ]).subscribe((translations) => {
            if(this.data.resource == ResourceType.device){
                this.resourceString = translations['resources.device']
            } else if(this.data.resource == ResourceType.department){
                this.resourceString = translations['resources.department']
            } else if(this.data.resource == ResourceType.workCenter){
                this.resourceString = translations['resources.workcenter']
            } else this.resourceString = 'UNDEFINED'
        });

    }


    onEditClicked(resourceId: number,type: ResourceType){
        this.editResourceClicked.emit({resourceId,type});
    }

    onResourceToggle(resourceId: number, value: boolean){
        this.resourceToggle.emit({resourceId,value});
    }


}