import { DeviceInstance } from "./device.model";
import { OperationInstance } from "./operation.model";
import { OrderInstance } from "./order.model";

export interface OrderDeviceIstance {
    orderId: number;
    deviceId: number;
    items?: number;
    scraps?: number;
    status?: OrderStatus;
    done?: boolean;
    priorityErrorTarget?: number;
}

export enum OrderStatus {
    draft = 1,
    planned = 2,
    running = 3,
    suspended = 4,
    completed = 5
}

export interface OrdersDevicesInstance {
    orderId?: number;
    deviceId?: number;
    operationId?: number;
    items?: number;
    scraps?: number;
    status?: OrderStatus;
    priorityErrorTarget?: number;
    Order?: OrderInstance;
    Device?: DeviceInstance;
    Operation?: OperationInstance;
}