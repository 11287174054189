import { AfterContentChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Drawer, FontService, NotificationConfig, NotificationService, OverlaySpinnerService, PopupBodyConfig, PopupConfig, PopupResult, PopupService } from '@vapor/angular-ui';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { Subscription } from 'rxjs';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { ProductionUnitsService } from '../../../services/productionUnits.service';
import { ProductionUnitsInstance } from '../../../models/production-units.model';
import { CompanyService } from '../../../services/company.service';
import { CompanyInstance } from '../../../models/company.model';
import { DrawerInstanceInfo } from '@vapor/angular-ui/drawer/drawer-data.model';
import { faPlus, faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'app-units',
    templateUrl: './units.component.html',
    styleUrls: ['./units.component.scss'],
    providers: [
        NotificationService,
    ],
})
export class UnitsComponent implements OnInit, OnDestroy, AfterContentChecked {

    @ViewChild('unitsList', { static: false, read: ElementRef }) ListRef!: ElementRef;

    company: CompanyInstance;
    selectedCompanyId: number;

    units: ProductionUnitsInstance[] = [];
    columns: TsTreeListColumn[] = [];
    listHeight = '100vh';

    private _subscriptions: Subscription[] = [];

    constructor(
        private readonly _translate: TranslateService,
        private readonly _router: Router,
        private readonly _font: FontService,
        private readonly _drawer: Drawer,
        private readonly _navbar: NavbarService,
        private readonly _sidebar: SidebarService,
        private readonly _spinner: OverlaySpinnerService,
        private readonly _notification: NotificationService,
        private readonly _popup: PopupService,
        public readonly _company: CompanyService,
        private readonly _productionUnits: ProductionUnitsService,
    ) {
        this._font.addIcon(faPlus, faPencil, faTrashAlt);

        // Collapse drawer when the user navigates to another page
        const routerEventSubscription =
            this._router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this._drawer.collapse();
                }
            });
        this._subscriptions.push(routerEventSubscription);
    }

    async ngOnInit(): Promise<void> {
        const translationSubscription = 
            this._translate.stream([
                'productionUnits.title',
                'productionUnits.columns.unit',
                'productionUnits.columns.description',
                'productionUnits.columns.decimals',
                'common.columns.actions',
            ]).subscribe(async (translations) => {
                this._navbar.setTitle(translations['productionUnits.title']);
                setTimeout(() => this._sidebar.setSelected('units'));
                this.columns = [
                    { // id
                        caption: 'id',
                        dataField: 'id',
                        showColumn: false,
                    },
                    { // unit
                        headerPlaceholder: translations['productionUnits.search'],
                        dataField: 'unit',
                        dataType: 'string',
                        caption: translations['productionUnits.columns.unit'],
                        width: 400,
                        allowEditing: false,
                        allowSorting: false,
                    },
                    { // description
                        headerPlaceholder: translations['productionUnits.search'],
                        dataField: 'description',
                        dataType: 'string',
                        caption: translations['productionUnits.columns.description'],
                        width: 500,
                        allowEditing: false,
                        allowSorting: false,
                    },
                    { // decimal
                        headerPlaceholder: translations['productionUnits.search'],
                        dataField: 'decimal',
                        dataType: 'string',
                        caption: translations['productionUnits.columns.decimals'],
                        cellTemplate: 'decimalType',
                        width: 300,
                        allowEditing: false,
                        allowSorting: false,
                    },
                    {
                        caption: "",
                        dataField: '',
                        showColumn: true,
                        allowEditing: false,
                        allowSorting: false,
                        allowFiltering: false,
                    },
                    { // actions
                        dataField: '',
                        caption: translations['common.columns.actions'],
                        cellTemplate: 'actionsType',
                        fixedPosition: 'right',
                        alignment: 'center',
                        width: 120,
                        allowFiltering: false,
                        allowResizing: false,
                        allowFixing: false,
                        allowSorting: false,
                        allowEditing: false
                    },
                ];
            });
        this._subscriptions.push(translationSubscription);
        

        this.setupDrawer();

        const companyChangeSubscription =
            this._company.changeCompanyEmitted$.subscribe(company => {
                this.companyChanged(company);
            });
        this._subscriptions.push(companyChangeSubscription);

        this.selectedCompanyId = parseInt(localStorage.getItem('companyId'));

        try {
            this._spinner.show();
            this.company = await this._company.getCompany(this.selectedCompanyId);
            await this.loadData(false);
        } catch (err) {
            this.catchError('Error while initiliaze table');
        } finally {
            this._spinner.removeOverlay();
        }
    }

    ngAfterContentChecked(): void {
        if (this.ListRef) {
            const offsetTop = this.ListRef.nativeElement.offsetTop;
            this.listHeight = `calc(100vh - ${offsetTop}px - 32px)`;
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    onNewClicked(): void {
        this._router.navigate(['/units/new']);
    }

    async onEditUnitClicked(object: ProductionUnitsInstance) {
        await this._router.navigate([`/units/edit/${object.id}`]);
    }

    onDeleteUnitClicked(object: ProductionUnitsInstance): void {
        try {
            this._translate.stream([
                'productionUnits.delete.title',
                'productionUnits.delete.text',
                'productionUnits.delete.confirmButton',
                'productionUnits.delete.cancelButton'
            ], { name: object.description }).subscribe((translations) => {
                const popupConfig: PopupConfig = {
                    title: translations['productionUnits.delete.title'],
                    level: 'error',
                    showCloseButton: true,
                    visible: true,
                    dragEnabled: false,
                    bottomButtons: {
                        primary: {
                            text: translations['productionUnits.delete.confirmButton'],
                        },
                        secondary: {
                            text: translations['productionUnits.delete.cancelButton'],
                        },
                    },
                };
                const bodyConfig: PopupBodyConfig = {
                    content: translations['productionUnits.delete.text'],
                };

                this._popup.show(popupConfig, bodyConfig).subscribe(async (button: PopupResult) => {
                    try {
                        if (button == 'primary') {
                            this._spinner.show();
                            await this._productionUnits.deleteUnit(object.id);
                            this._translate.stream([
                                'productionUnits.delete.success',
                            ], { name: object.description }).subscribe(async (translations) => {
                                await this.loadData(false);

                                const config: NotificationConfig = {
                                    content: translations['productionUnits.delete.success'],
                                    type: 'toast',
                                    style: 'check',
                                    timeout: 5000,
                                    position: 'right',
                                };
                                this._notification.show(config);
                            });
                        }
                    } catch (err) {
                        this.catchError(err);
                    } finally {
                        this._spinner.removeOverlay();
                    }
                });
            });
        } catch (err) {
            this.catchError(err);
        }
    }

    private setupDrawer(): void {
        const toggleSubscription =
            this._drawer.toggle$.subscribe((toggleValue: boolean) => {
                // Close drawers when they are collapsed, in order to clean up the resources
                if (!toggleValue) {
                    // Wait for the collapse animation
                    setTimeout(() => {
                        // Close the drawer
                        this._drawer.close();
                    }, 550);
                }
            });
        this._subscriptions.push(toggleSubscription);

        const closeSubscription =
            this._drawer.close$.subscribe((drawerInfo: DrawerInstanceInfo) => {
                console.info(`Drawer closed: ${drawerInfo.drawerId}`);
            });
        this._subscriptions.push(closeSubscription);
    }

    private async companyChanged(company: CompanyInstance): Promise<void> {
        this.company = company;
        this.selectedCompanyId = company.id;
        this._drawer.collapse();
        await this.loadData(true);
    }

    private catchError(error: string) {
        console.error(error);
        this._translate.stream([
            'orders-list.filters.error',
        ]).subscribe((translations) => {
            const config: NotificationConfig = {
                content: translations['orders-list.filters.error'],
                type: 'toast',
                style: 'error',
                timeout: 5000,
                position: 'right',
            };
            this._notification.show(config);
        });
    }

    private async loadData(showSpinner: boolean): Promise<void> {
        this.units = [];
        if (showSpinner) {
            this._spinner.show();
        }
        try {
            this.units = await this._productionUnits.getUnits(this.selectedCompanyId);
        } catch (err) {
            this.catchError(err);
        } finally {
            if (showSpinner) {
                this._spinner.hide();
            }
        }
    }

}
