import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ConfigService } from './config.service';
import { PluginInstance } from '../models/plugin.model';

@Injectable()
export class PluginService {
    private _baseUrl = 'plugins';

    constructor(
        private _http: HttpService,
        private _config: ConfigService
    ) { }

    async getPlugins(deviceId: number) {
        const url = `${this._config.API.url}/${this._baseUrl}/${deviceId}`;
        return this._http.get<PluginInstance[]>(url).then( response => {
            const plugins = response.body;
            return plugins;
        }).catch( error => {
            console.log(error);
            return [];
        });
    }

    async createPlugin(deviceId: number, plugin: PluginInstance) {
        const url = `${this._config.API.url}/${this._baseUrl}/${deviceId}`;
        return this._http.post<PluginInstance>(url, plugin).then( response => {
            const plugins = response.body;
            return plugins;
        }).catch( error => {
            console.log(error);
            return null;
        });
    }

    async updatePlugin(deviceId: number, plugin: PluginInstance) {
        const url = `${this._config.API.url}/${this._baseUrl}/${deviceId}`;
        return this._http.put<PluginInstance>(url, plugin).then( response => {
            const plugins = response.body;
            return plugins;
        }).catch( error => {
            console.log(error);
            return null;
        });
    }
}
