export enum ResourceType {
    device = 1,
    department = 2,
    workCenter = 3,
}

export interface Resource {
    id?: number;
    code?: string;
    resource?: ResourceType;
    keyId?: number;
}

export interface DeviceResource extends Resource{
    label: string;
    resource: ResourceType.device;
}

export interface WorkCenterResource extends Resource{
    description: string;
    resource: ResourceType.workCenter;
    Devices?: DeviceResource[];
}

export interface DepartmentResource extends Resource{
    description: string;
    resource: ResourceType.department;
    WorkCenters?: WorkCenterResource[];
    Devices?: DeviceResource[];
}

export interface ResourceTree extends Array<DeviceResource | WorkCenterResource | DepartmentResource>{}